import { v4 as uuidv4 } from 'uuid'
import { useAuthStore } from '~/stores/auth'

/**
 * Retrieves the cmpConfig from the route or directly from the browser.
 *
 * @return {any} The cmpConfig value.
 */
const getCmpConfig = () => {
    // Get cmpConfig from route or directly from browser
    const route = useRoute()
    const hash = route.hash || ''

    // Check if the hash includes 'cmpimport' and split it correctly
    const cmpImportHash = hash.includes('cmpimport') ? hash.split('=')[1] : null

    // Fallback to the __cmp function if cmpImportHash is not available
    return cmpImportHash || (typeof __cmp === 'function' && __cmp('exportConsent'))
}

/**
 * Function to make a request to Azure with a specified policy.
 *
 * @param {Object} configObj - The configuration object for the request.
 * @param {string} [configObj.context=''] - Additional context for the request.
 * @param {Object} [configObj.state={}] - Additional state object for the request.
 * @param {string} [configObj.center=''] - Additional centerCode for the request.
 * @param {string} [configObj.centerName=''] - Additional centerFullName for the request.
 * @return {Promise<void>} - A promise that resolves once the request is completed.
 */
const signIn = async (configObj) => {
    try {
        const { $msal } = useNuxtApp()
        const config = useRuntimeConfig()

        const {
            context,
            state,
            center,
            centerName,
        } = configObj

        /*
        // Set password reset policy
        const isPasswordReset = policy === 'passwordReset'

        if (isPasswordReset) {
            $msal.config.auth.authority = `https://${config.public.azureAdUrl}.b2clogin.com/${config.public.azureAdUrl}.onmicrosoft.com/B2C_1A_PASSWORDRESET`
        }
        */

        const scopes = [
            'openid',
            `https://${config.public?.azureAdUrl}.onmicrosoft.com/apiGateway/accessApiGateway`,
            'offline_access'
        ]

        await $msal?.loginRedirect({
            extraQueryParameters: {
                'platform': 'webapp',
                center,
                centerName,
                'X-Correlation-Id': uuidv4(),
                'gtmContainerId': config.public?.gtmContainerId,
                '#cmpimport': getCmpConfig(),
                context
            },
            scopes,
            state
        })
    } catch (err) {
        // handle error
        console.log(`MSAL err:  ${err}`)
    }
}

/**
 * Sign out the user.
 *
 * @param {obj} route - The URI to redirect to after logout.
 * @param {string} redirectUri - The redirectUri
 * @return {Promise<void>} - A promise that resolves when the user has been signed out.
 */
const signOut = async (route, redirectUri = null) => {
    try {
        const { $msal } = useNuxtApp()
        const authCookieOptions = getCookieOptions()
        const authCookie = useCookie('auth-cookie', authCookieOptions)

        // Delete cookie
        authCookie.value = null

        // Create correlation id
        const uuid = uuidv4()

        // Get redirectUrl
        const isAuthProtected = route.meta?.auth
        const routePath = redirectUri ? redirectUri : route.fullPath
        const redirectUrl = isAuthProtected ? `/${route?.params?.centerUid}` : routePath

        await $msal.logoutRedirect({
            extraQueryParameters: { 'X-Correlation-Id': uuid },
            postLogoutRedirectUri: redirectUrl
        })
    } catch (err) {
        console.warn(`Logout error: ${err}`)
    }
}

/**
 * Acquires an access token silently.
 *
 * @return {Promise<void>} This function does not return anything.
 */
const acquireTokenSilent = async () => {
    const authStore = useAuthStore()
    const { updateLoggedInStatus } = authStore
    const { isLoggedIn } = storeToRefs(authStore)

    if (import.meta.client) {
        const { $msal } = useNuxtApp()
        const config = useRuntimeConfig()

        const accounts = $msal?.getAllAccounts()

        if (accounts?.length > 0) {
            const scopes = [
                'openid',
                `https://${config.public.azureAdUrl}.onmicrosoft.com/apiGateway/accessApiGateway`,
                'offline_access'
            ]

            // Define cookie
            const authCookieOptions = getCookieOptions()
            const authCookie = useCookie('auth-cookie', authCookieOptions)

            // attempt to acquire a token silently
            try {
                const response = await $msal.acquireTokenSilent({
                    extraQueryParameters: {
                        'X-Correlation-Id': uuidv4()
                    },
                    scopes,
                    account: accounts[0]
                })

                // Get values
                const bearerToken = response?.accessToken
                const oid = response?.account?.idTokenClaims?.oid
                const ignoreOnBoarding = response?.state === 'ignoreOnBoarding'

                // Set cookie for auth middleware
                authCookie.value = {
                    bearerToken
                }

                // Update login status
                updateLoggedInStatus(true)

                // Now set some global states
                useState('state_bearerToken', () => bearerToken)
                useState('state_userOid', () => oid)
                useState('state_showOnboarding', () => !ignoreOnBoarding)
            } catch (err) {
                console.log(`Silent token acquisition failed: ${err}`)

                // Delete Cookie
                authCookie.value = null

                // Update login status
                updateLoggedInStatus(false)
            }
        } else {
            // Update login status if still logged in
            console.log('No accounts set in MSAL')

            if (isLoggedIn.value) {
                updateLoggedInStatus(false)
            }
        }
    }
}

export {
    signIn,
    signOut,
    acquireTokenSilent
}
