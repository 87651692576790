export default (payload) => {
    const { bearerToken, oid = null, correlationId = null } = payload

    const fetchProfile = async () => {
        try {
            const apiUrl = '/api/consumers/profiles/getProfile'
            const headers = {
                'Authorization': `Bearer ${bearerToken.value}`,
                'User-Oid': oid?.value,
                ...(correlationId?.value ? { 'X-Correlation-Id': correlationId.value } : {})
            }

            const data = await $fetch(apiUrl, { headers })

            return data ?? {} // Ensure it never returns undefined
        } catch (error) {
            console.error('Error fetching profile:', error)
            return {} // Return an empty object instead of undefined
        }
    }

    return useFetchWithTanstackQuery({
        queryKey: ['user', oid?.value],
        queryFn: fetchProfile,
        storageType: null,
        preventFatalError: true, // Handle profile errors more specific
        options: {
            enabled: !!bearerToken?.value && !!oid?.value // Ensures valid inputs before running
        }
    })
}
