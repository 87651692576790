import { adaptUserMetaForGTM, adaptPageLoadForGTM, adaptUserClickForGTM } from '~/utils/trackingAdapters'

const trackingProviders = {
    GTM: {
        adapter: {
            USER_META: adaptUserMetaForGTM,
            PAGE_LOAD: adaptPageLoadForGTM,
            USER_CLICK: adaptUserClickForGTM
        },
        send: (data) => {
            if (import.meta.client) {
                window.dataLayer = window.dataLayer || []

                const gtmContainerId = useRuntimeConfig().public.gtmContainerId

                if (
                    window.google_tag_manager &&
                    window.google_tag_manager[gtmContainerId] &&
                    typeof window.google_tag_manager[gtmContainerId].dataLayer?.reset === 'function'
                ) {
                    window.google_tag_manager[gtmContainerId].dataLayer.reset()
                } else {
                    console.warn('GTM dataLayer reset function is unavailable.')
                }

                window.dataLayer.push(data)
            }
        }
    }
}

/**
 * Sends tracking data to one or more tracking providers after adapting it to each provider's format.
 *
 * This function accepts a payload that includes:
 * - Raw, domain-specific tracking data.
 * - An array of tracking provider identifiers.
 * - A tracking context to determine which adapter function to use.
 *
 * For each provider in the array, the function retrieves the corresponding provider configuration,
 * adapts the domain data using the provider-specific adapter based on the given tracking context,
 * and then sends the adapted data using the provider's send function.
 *
 * @param {Object} payload - The payload for sending tracking data.
 * @param {Object} payload.domainData - The raw, domain-specific tracking data.
 * @param {string[]} payload.providers - An array of tracking provider identifiers (e.g., ['GTM', 'CUSTOM_PROVIDER']).
 * @param {string} payload.trackingContext - The context for tracking, used to select the appropriate adapter function.
 * @returns {void}
 */
const sendToProvider = (payload) => {
    const { domainData, providers, trackingContext } = payload

    providers.forEach((provider) => {
        const providerObj = trackingProviders[provider]

        if (!providerObj) {
            console.warn(`Tracking provider "${provider}" is not supported.`)
            return
        }

        // Adapt domain data to the provider-specific format based on the tracking context
        const adaptedData = providerObj.adapter[trackingContext](domainData)

        // Send the adapted data to the provider
        providerObj.send(adaptedData)
    })
}

export { sendToProvider }
