import { useQuery } from '@tanstack/vue-query'
import { experimental_createPersister } from '@tanstack/query-persist-client-core'

export default (payload) => {
    const {
        queryKey,
        queryFn,
        storageType = 'sessionStorage',
        options = {},
        preventFatalError = false,
    } = payload

    // Determine if persister should be created (only on client-side)
    const persister = (() => {
        if (import.meta.client && storageType) {
            return experimental_createPersister({
                storage: storageType === 'sessionStorage' ? sessionStorage : localStorage
            })
        }
        return null
    })()

    // Default options
    const defaultOptions = {
        staleTime: 24 * 60 * 60 * 1000, // 24 hours
        cacheTime: 3 * 24 * 60 * 60 * 1000, // 3 days
        enabled: true, // Query is enabled by default
        refetchOnMount: true, //  Re-fetch on mount
        refetchOnReconnect: true, // Re-fetch on network reconnect
        refetchOnWindowFocus: false, // No re-fetch on focus
        persister, // Only add persister if created
        ...options, // Merge additional options passed in payload
    }

    // Use Vue Query
    const { data, error, isError, isPlaceholderData, suspense, isLoading, refetch } = useQuery({
        queryKey,
        queryFn,
        ...defaultOptions,
    })

    // Handle errors gracefully
    watch(
        () => isError.value,
        (newValue) => {
            if (newValue && !preventFatalError) {
                throw createError({
                    statusCode: error.value?.statusCode || 500,
                    message: error.value?.message || 'An error occurred while fetching data',
                    fatal: true
                })
            }
        },
        { immediate: true }
    )

    return {
        data,
        error,
        isError,
        isPlaceholderData,
        suspense,
        isLoading,
        refetch
    }
}
