import { defineStore } from 'pinia'

export const useMessageStorePrivate = defineStore(
    'message-store-private',
    () => {
        const messages = ref(null)

        return {
            messages
        }
    }
)

export const useMessageStore = defineStore(
    'message-store',
    () => {
        const messageStorePrivate = useMessageStorePrivate()

        // Get messages
        const messages = computed(() => messageStorePrivate.messages)

        /**
         * Updates the messages with the given payload.
         *
         * @param {Object} payload - The payload containing the messages to update.
         * @return {undefined} - This function does not return anything.
         */
        const updateMessages = (payload) => {
            if (payload) {
                if (messageStorePrivate.messages) {
                    messageStorePrivate.messages = {
                        ...messages.value,
                        ...payload
                    }
                } else {
                    messageStorePrivate.messages = payload
                }
            } else {
                messageStorePrivate.messages = null
            }
        }

        return {
            messages,
            updateMessages
        }
    }
)
