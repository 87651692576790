/**
 * Checks if any of the specified keys exist in the provided query parameters object.
 *
 * @param {Array<string>} keys - The keys to check in the query parameters.
 * @param {Object} queryParams - The query parameters object to check against.
 * @return {boolean} Returns true if at least one of the keys exists in the query parameters, otherwise false.
 */
const hasAnyQueryParam = (keys, queryParams) => {
    return keys.some(key => queryParams[key] !== undefined)
}

/**
 * Checks if two arrays contain the same elements, regardless of order.
 *
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @return {boolean} Returns true if both arrays contain the same elements, false otherwise.
 */
const isEqualUnorderedArray = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false
    }

    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()

    return sortedArr1.every((value, index) => value === sortedArr2[index])
}

/**
 * Determines whether a given string represents a valid image file based on its file extension.
 *
 * @param {string} thumbnailString - The filename or path of the image.
 * @return {boolean} Returns true if the string has a valid image file extension, otherwise false.
 */
const isTypeImage = (thumbnailString) => {
    if (!thumbnailString) {
        return false
    }

    const parts = thumbnailString.split('.')
    const ext = parts[parts.length - 1]
    const allowedImageExtensions = ['png', 'jpg', 'avif', 'webp', 'jpeg', 'PNG', 'JPG', 'AVIF', 'WEBP', 'JPEG']

    return allowedImageExtensions.includes(ext)
}

/**
 * Determines whether a given date range represents a single day.
 *
 * @param {string|null} from - The start date in string format.
 * @param {string|null} till - The end date in string format.
 * @return {boolean} Returns true if both dates are the same day, otherwise false.
 */
const isValidForOneDay = (from, till) => {
    const fromDate = from ? new Date(from) : null
    const tillDate = till ? new Date(till) : null

    if (!fromDate || !tillDate) {
        return false
    }

    return (
        fromDate.getFullYear() === tillDate.getFullYear() &&
        fromDate.getMonth() === tillDate.getMonth() &&
        fromDate.getDate() === tillDate.getDate()
    )
}

export {
    hasAnyQueryParam,
    isEqualUnorderedArray,
    isTypeImage,
    isValidForOneDay
}
