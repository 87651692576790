export default function useUpdateProfileMutation(payload) {
    const {
        bearerToken,
        oid,
        onSuccessCallback,
        onErrorCallback,
        onMutateCallback,
        onSettledCallback,
    } = payload

    const headers = {
        'Authorization': `Bearer ${bearerToken.value}`,
        'User-Oid': oid.value,
    }

    const apiUrl = '/api/consumers/profiles/patchProfile'
    const mutationKey = ['profileUpdate', oid.value]

    return useTanstackMutation({
        mutationKey,
        apiUrl,
        headers,
        onSuccessCallback,
        onErrorCallback,
        onMutateCallback,
        onSettledCallback,
    })
}
