/**
 * Updates the router path with new query parameters while preserving the current path and excluding specific query parameters.
 *
 * This function filters out certain predefined query parameters from the current route query and merges the remaining
 * parameters with any new query parameters provided. If the resulting query differs from the current one, it triggers
 * navigation using the `navigateTo` function with the updated path and query.
 *
 * @param {Object} [payload={}] - The payload object containing options for path, query parameters, and the current route.
 * @param {string|null} [payload.path=null] - The optional path to navigate to. Defaults to the current route's path.
 * @param {Object} [payload.query={}] - The new query parameters to merge with the existing ones.
 * @param {Object} [payload.route=useRoute()] - The current route object. If not provided, defaults to the current route.
 *
 * @returns {Promise} - Returns a promise that resolves after the navigation operation completes, or resolves immediately if no query changes.
 */
export const navigateWithSidebarQuery = async (payload = {}) => {
    const { path = null, query = {}, route } = payload

    // Fetch runtime config
    const config = useRuntimeConfig()

    // Get predefined query keys from public config with default values (empty arrays if not defined)
    const queryParamsToContentMap = config?.public?.queryParamsToContentMap
    const excludedParamsArray = Object.keys(queryParamsToContentMap || {}) || []

    // Define the new query params (keep all existing except the ones in excludedParamsArray)
    const newQuery = Object.fromEntries(
        Object.entries(route.query).filter(([key]) => !excludedParamsArray.includes(key))
    )

    // Merge the new query with the existing ones
    const finalQuery = {
        ...newQuery,
        ...query
    }

    // Check if the new query is different from the current query
    if (JSON.stringify(finalQuery) !== JSON.stringify(route.query)) {
        return navigateTo({
            path: path || route.path,
            query: finalQuery
        })
    }
}
