import { useParkingStore } from '~/stores/parking'

export default defineNuxtRouteMiddleware(async (to, from) => {
    // Fetch parking store
    const parkingStore = useParkingStore()
    const { resetParkingStore } = parkingStore

    const isMobilePaymentSuccessPage = (from.meta?.paymentSuccess && from.query?.scope === 'mobileTicketPayment')
    const isMobilePaymentFailurePage = (from.meta?.paymentFailure && from.query?.scope === 'mobileTicketPayment')
    const isNotParkingOverviewPage = !to.meta?.parkingOverviewPage

    // Reset parking store on mobile payment but not in case you failed and try to pay ticket
    if (isMobilePaymentSuccessPage || (isMobilePaymentFailurePage && isNotParkingOverviewPage)) {
        resetParkingStore()
    }
})
