import { centerDetailsPlaceHolderData } from '@/utils/placeholderData'

export default (centerUid) => {
    return useFetchWithTanstackQuery({
        queryKey: ['centerDetails', centerUid],
        queryFn: () => $fetch(`/api/center/centers/${centerUid}`),
        options: {
            enabled: !!centerUid,
            placeholderData: centerDetailsPlaceHolderData
        }
    })
}
