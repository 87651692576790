import { defineStore } from 'pinia'
import { useQueryClient } from '@tanstack/vue-query'
import { useSideBarStore } from '~/stores/sideBar'

export const useAuthStorePrivate = defineStore(
    'auth-store-private',
    () => {
        const loggedIn = ref(false)
        const userStatus = ref(null)

        return {
            loggedIn,
            userStatus
        }
    }
)

export const useAuthStore = defineStore(
    'auth-store',
    () => {
        const { toggleSideBarLayer } = useSideBarStore()
        const queryClient = useQueryClient()

        // Private auth state
        const authStorePrivate = useAuthStorePrivate()

        // Login status
        const isLoggedIn = computed(() => authStorePrivate.loggedIn)

        /**
         * Updates the current user with the given payload.
         *
         * @param {Object} payload - The payload containing the updated user data.
         */
        const updateLoggedInStatus = (payload) => {
            authStorePrivate.loggedIn = payload
        }

        // User Status
        const userStatus = computed(() => authStorePrivate.userStatus)

        /**
         * Updates the status with the given value.
         *
         * @param {any} value - The value to update the status object with.
         */
        const updateUserStatus = (value) => {
            if (value) {
                if (authStorePrivate.userStatus) {
                    authStorePrivate.userStatus = {
                        ...authStorePrivate.userStatus,
                        ...value
                    }
                } else {
                    authStorePrivate.userStatus = {
                        ...value
                    }
                }
            } else {
                authStorePrivate.userStatus = null
            }
        }

        /**
         * Profile update success callback
         *
         * @param payload
         * @returns {Promise<void>}
         */
        const updateProfileSuccessCallback = async (payload) => {
            const { successMessage, closeLayer = false } = payload

            updateUserStatus({
                success: true,
                message: successMessage,
            })

            setTimeout(() => {
                if (closeLayer) {
                    toggleSideBarLayer(false)
                }

                updateUserStatus(null)
            }, 2000)
        }

        /**
         * Profile update error callback
         *
         * @param payload
         */
        const updateProfileErrorCallback = (payload) => {
            const { oid, errorMessage, context } = payload

            if (context?.previousUserData) {
                queryClient.setQueryData(['user', oid.value], context.previousUserData)
            }

            updateUserStatus({
                success: false,
                message: errorMessage,
            })
        }

        /**
         * Profile update onSettled callback
         *
         * @param payload
         */
        const updateProfileOnSettledCallback = async (payload) => {
            const { oid } = payload

            await queryClient.invalidateQueries({
                queryKey: ['user', oid.value],
                exact: true,
            })
        }

        /**
         * Profile update onMutate callback
         *
         * @param payload
         */
        const updateProfileOnMutateCallback = (payload) => {
            const { body: newData, oid } = payload

            queryClient.cancelQueries(['user', oid.value]) // No await to avoid delays

            const previousUserData = queryClient.getQueryData(['user', oid.value])

            queryClient.setQueryData(['user', oid.value], old => ({
                ...old,
                data: {
                    ...old.data,
                    ...newData, // Apply optimistic update
                },
            }))

            return { previousUserData } // Return for rollback if needed
        }

        return {
            isLoggedIn,
            updateLoggedInStatus,
            userStatus,
            updateUserStatus,
            updateProfileSuccessCallback,
            updateProfileErrorCallback,
            updateProfileOnSettledCallback,
            updateProfileOnMutateCallback,
        }
    }
)
