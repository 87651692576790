
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en"
]

export const localeLoaders = {
  "de": [],
  "en": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    "de",
    "en"
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "config",
  "pages": {
    "[centerUid]": {
      "de": "/[centerUid]",
      "en": "/[centerUid]"
    },
    "faq": {
      "de": "/faq",
      "en": "/faq"
    },
    "imprint": {
      "de": "/impressum",
      "en": "/imprint"
    },
    "dataProtection": {
      "de": "/datenschutz",
      "en": "/data-protection"
    },
    "legal": {
      "de": "/rechtliches",
      "en": "/legal"
    },
    "terms": {
      "de": "/agb",
      "en": "/terms"
    },
    "[centerUid]/faq": {
      "de": "/[centerUid]/faq",
      "en": "/[centerUid]/faq"
    },
    "[centerUid]/imprint": {
      "de": "/[centerUid]/impressum",
      "en": "/[centerUid]/imprint"
    },
    "[centerUid]/dataProtection": {
      "de": "/[centerUid]/datenschutz",
      "en": "/[centerUid]/data-protection"
    },
    "[centerUid]/legal": {
      "de": "/[centerUid]/rechtliches",
      "en": "/[centerUid]/legal"
    },
    "[centerUid]/terms": {
      "de": "/[centerUid]/agb",
      "en": "/[centerUid]/terms"
    },
    "[centerUid]/shops": {
      "de": "/[centerUid]/shops",
      "en": "/[centerUid]/shops"
    },
    "[centerUid]/shops/[shopUid]": {
      "de": "/[centerUid]/shops/[shopUid]",
      "en": "/[centerUid]/shops/[shopUid]"
    },
    "[centerUid]/shops/[shopUid]/coupons": {
      "de": "/[centerUid]/shops/[shopUid]/coupons",
      "en": "/[centerUid]/shops/[shopUid]/coupons"
    },
    "[centerUid]/coupons": {
      "de": "/[centerUid]/coupons",
      "en": "/[centerUid]/coupons"
    },
    "[centerUid]/parking": {
      "de": "/[centerUid]/parken",
      "en": "/[centerUid]/parking"
    },
    "[centerUid]/parking/payTicket": {
      "de": "/[centerUid]/parken/ticket-bezahlen",
      "en": "/[centerUid]/parking/pay-ticket"
    },
    "[centerUid]/parking/payment/history": {
      "de": "/[centerUid]/parken/bezahlhistorie",
      "en": "/[centerUid]/parking/payment-history"
    },
    "[centerUid]/events/[eventsUid]/": {
      "de": "/[centerUid]/events/[eventsUid]",
      "en": "/[centerUid]/events/[eventsUid]"
    },
    "[centerUid]/news/[newsUid]/": {
      "de": "/[centerUid]/news/[newsUid]",
      "en": "/[centerUid]/news/[newsUid]"
    },
    "payment/success": {
      "de": "/payment/success",
      "en": "/payment/success"
    },
    "payment/failure": {
      "de": "/payment/failure",
      "en": "/payment/failure"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18n": {
    "vueI18n": "./i18n.config.ts"
  },
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "language": "de",
    "code": "de"
  },
  {
    "language": "en",
    "code": "en"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
