import { centerListPlaceHolderData } from '~/utils/placeholderData'

export default () => {
    return useFetchWithTanstackQuery({
        queryKey: ['centerList'],
        queryFn: () => $fetch('/api/center/centers'),
        options: {
            placeholderData: centerListPlaceHolderData
        }
    })
}
