import { sendToProvider } from '@/utils/trackingProviders'

/**
 * Tracks a user click event by sending domain-specific data to the tracking provider.
 *
 * This function extracts tracking data from the given object, and then calls the specified provider function
 * (defaulting to sendToProvider) with a payload configured for a user click event.
 *
 * @param {Object} domainData - The domain-specific tracking data for a user click event.
 * @param {Function} [domainData.providerFn=sendToProvider] - Optional custom function to send tracking data.
 * @returns {void}
 */
const trackUserClick = (domainData) => {
    const {
        providerFn = sendToProvider,
        ...data
    } = domainData

    providerFn({
        domainData: data,
        providers: ['GTM'],
        trackingContext: 'USER_CLICK'
    })
}

/**
 * Tracks a page load event by sending domain-specific data to the tracking provider.
 *
 * This function extracts tracking data from the given object, and then calls the specified provider function
 * (defaulting to sendToProvider) with a payload configured for a page load event.
 *
 * @param {Object} domainData - The domain-specific tracking data for a page load event.
 * @param {Function} [domainData.providerFn=sendToProvider] - Optional custom function to send tracking data.
 * @returns {void}
 */
const trackPageLoad = (domainData) => {
    const {
        providerFn = sendToProvider,
        ...data
    } = domainData

    providerFn({
        domainData: data,
        providers: ['GTM'],
        trackingContext: 'PAGE_LOAD'
    })
}

/**
 * Tracks user meta-data by sending domain-specific data to the tracking provider.
 *
 * This function extracts tracking data from the given object, and then calls the specified provider function
 * (defaulting to sendToProvider) with a payload configured for a user meta event.
 *
 * @param {Object} domainData - The domain-specific tracking data for user meta-data.
 * @param {Function} [domainData.providerFn=sendToProvider] - Optional custom function to send tracking data.
 * @returns {void}
 */
const trackUserMeta = (domainData) => {
    const {
        providerFn = sendToProvider,
        ...data
    } = domainData

    providerFn({
        domainData: data,
        providers: ['GTM'],
        trackingContext: 'USER_META'
    })
}

export {
    trackUserClick,
    trackPageLoad,
    trackUserMeta
}
