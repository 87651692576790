export default defineNuxtRouteMiddleware(async (to) => {
    if (to.meta?.webview) return

    const centerUid = to?.params?.centerUid

    // Fetch center list items and add placeholder property to each entry
    const { data: centers, suspense: centerSuspense } = useFetchCenterList()

    await centerSuspense() // Wait for the query to finish fetching

    const isValidCenterRoute = centers.value?.some(item => item.uid === centerUid)

    if (centerUid && !isValidCenterRoute) {
        throw createError({
            statusCode: 404, // HTTP status code
            statusMessage: `Diese Seite existiert nicht: ${centerUid}`, // Error message
            fatal: true, // Optional flag to indicate it's a fatal error
        })
    }
})
