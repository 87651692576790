import { storeToRefs } from 'pinia'
import { useSideBarStore } from '~/stores/sideBar'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (to.meta?.webview) return

    // Fetch runtime config
    const config = useRuntimeConfig()

    const localePath = useLocalePath()

    // Fetch sidebar store
    const sideBarStore = useSideBarStore()
    const { toggleSideBar, toggleSideBarOverlay, toggleSideBarSubNav } = sideBarStore
    const { isSideBarLayerOpen } = storeToRefs(sideBarStore)

    // Fetch auth store
    const authStore = useAuthStore()
    const { isLoggedIn } = storeToRefs(authStore)

    // Define favouriteCenter in cookie
    const favouriteCenter = useCookie('favourite-center-cookie', getCookieOptions())

    // Define some basics
    const params = to.params
    const isCenterPage = params?.centerUid

    const queryParams = to.query
    const queryParamsArray = Object.keys(queryParams) || []
    const queryParamExist = queryParamsArray.length > 0
    const queryParamsToContentMap = config?.public?.queryParamsToContentMap

    // Define an array of query keys to react to
    const reactToQueryParams = Object.keys(queryParamsToContentMap) || []

    // Now we need to check if we have to react on queries that require a center page
    const requireCenterPage = getKeysByCondition(queryParamsToContentMap, 'requiresCenterPage')

    // Now we need to check if we have to react on query's that require auth
    const requireAuth = getKeysByCondition(queryParamsToContentMap, 'auth')

    // Check if we have query params that require action
    const queryParamExistsInReactToParams = hasAnyQueryParam(reactToQueryParams, queryParams)

    // Find out the query params that require center page
    const queryParamExistsInRequireCenterPage = hasAnyQueryParam(requireCenterPage, queryParams)

    // Find out the query params that require authentication
    const queryParamExistsInRequireAuth = hasAnyQueryParam(requireAuth, queryParams)

    if (queryParamExist && queryParamExistsInReactToParams) {
        // Define an object mapping query keys to sidebar configurations
        const contentConfig = getMappedContentConfig(queryParamsToContentMap)

        // Iterate over the query keys and check various use cases
        for (const queryKey of Object.keys(contentConfig)) {
            if (queryParams[queryKey] === undefined) continue // Early return if the query param is not defined

            const { open, content, sideBarType, triggerConsent, auth } = contentConfig[queryKey]

            if (import.meta.client) {
                if (triggerConsent) {
                    showCMP()
                    continue // No further processing needed if CMP is shown
                }

                // Check if we are on a center page
                if (!isCenterPage) {
                    // Query params require auth or is center specific
                    if (queryParamExistsInRequireAuth || queryParamExistsInRequireCenterPage) {
                        // User has saved local value for favourite center
                        if (favouriteCenter.value) {
                            // Navigate to the center page with params
                            return navigateTo({
                                path: localePath({
                                    name: 'centerUid',
                                    params: { centerUid: favouriteCenter?.value?.centerRoute }
                                }),
                                query: queryParams
                            })
                        }

                        // Handle center selection when center code is not known
                        const sidebarContextArr = Object.keys(queryParams).filter(item => [...requireCenterPage, ...requireAuth].includes(item))

                        // We need context to display different content on sidebar (link, introtext)
                        const sidebarContext = sidebarContextArr.length > 0 ? sidebarContextArr[0] : null

                        // Open center selector with config that will change intro text and behaviour of center items
                        // No query params are used here
                        toggleSideBar({
                            open,
                            content: 'centerSelector',
                            config: {
                                queryParams,
                                sidebarContext
                            }
                        })
                    } else {
                        // Query params do not require auth or is center specific, go to sidebar
                        handleSidebar(open, content, sideBarType, isSideBarLayerOpen.value)
                    }
                } else {
                    // We're on a center page
                    if (auth) {
                        if (isLoggedIn.value) {
                            // User is logged in, show sidebar
                            handleSidebar(open, content, sideBarType, isSideBarLayerOpen.value)
                        }
                        // If not logged in, the component will handle login
                    } else {
                        // No auth required, just open sidebar
                        handleSidebar(open, content, sideBarType, isSideBarLayerOpen.value)
                    }
                }
            }
        }
    } else {
        toggleSideBarOverlay({ open: false })
        toggleSideBar({ open: false })
        toggleSideBarSubNav({ open: false })
    }
})
