import { defineStore, storeToRefs } from 'pinia'
import { StorageSerializers, useSessionStorage } from '@vueuse/core'
import { useMessageStore } from '~/stores/messages'
import { useModalStore } from '~/stores/modal'

export const useParkingStorePrivate = defineStore(
    'parking-store-private',
    () => {
        const paymentProgress = ref(0)
        const savedTicketVoucher = ref('')
        const ticketFormValidationStatus = ref(null)
        const ticketFormValues = ref(null)
        const isForeignLicensePlateVisible = ref(false)
        const ticketInfos = ref(null)
        const ticketStatus = ref(null)

        return {
            paymentProgress,
            savedTicketVoucher,
            ticketFormValidationStatus,
            ticketFormValues,
            isForeignLicensePlateVisible,
            ticketInfos,
            ticketStatus,
        }
    }
)

export const useParkingStore = defineStore(
    'parking-store',
    () => {
        // Private parking store
        const parkingStorePrivate = useParkingStorePrivate()

        // Define getters
        const paymentProgress = computed(() => parkingStorePrivate.paymentProgress.toString())
        const savedTicketVoucher = computed(() => parkingStorePrivate.savedTicketVoucher)
        const ticketFormValidationStatus = computed(() => parkingStorePrivate.ticketFormValidationStatus)
        const ticketFormValues = computed(() => parkingStorePrivate.ticketFormValues)
        const isForeignLicensePlateVisible = computed(() => parkingStorePrivate.isForeignLicensePlateVisible)
        const ticketInfos = computed(() => parkingStorePrivate.ticketInfos)
        const ticketStatus = computed(() => parkingStorePrivate.ticketStatus)

        /**
         * Updates the ticket voucher with a new value.
         *
         * @param {any} value - The new value to set for the ticket voucher.
         */
        const updateTicketVoucher = (value) => {
            parkingStorePrivate.savedTicketVoucher = value
        }

        /**
         * Sets the payment progress to the given value.
         *
         * @param {type} value - the value to set the payment progress to
         * @return {type}
         */
        const updateTicketPaymentProgress = (value) => {
            parkingStorePrivate.paymentProgress = value
        }

        /**
         * Updates the ticket validation form with the given value.
         *
         * @param {type} payload - The value to update the form with
         * @return {type} undefined
         */
        const updateTicketFormValues = (payload) => {
            if (payload) {
                if (parkingStorePrivate.ticketFormValues) {
                    parkingStorePrivate.ticketFormValues = {
                        ...parkingStorePrivate.ticketFormValues,
                        ...payload
                    }
                } else {
                    parkingStorePrivate.ticketFormValues = {
                        ...payload
                    }
                }
            } else {
                parkingStorePrivate.ticketFormValues = null
            }
        }

        /**
         * Toggle the visibility of the foreign license plate
         *
         * @param {type} value - description of parameter
         * @return {type} undefined - description of return value
         */
        const toggleLicensePlateVisibility = (value) => {
            parkingStorePrivate.isForeignLicensePlateVisible = value
        }

        /**
         * Updates the ticket validation status with new data.
         *
         * @param {Object} payload - An object containing the new data to be merged into the current ticket validation status.
         */
        const updateTicketFormValidationStatus = (payload) => {
            if (payload) {
                if (parkingStorePrivate.ticketFormValidationStatus) {
                    parkingStorePrivate.ticketFormValidationStatus = {
                        ...parkingStorePrivate.ticketFormValidationStatus,
                        ...payload
                    }
                } else {
                    parkingStorePrivate.ticketFormValidationStatus = {
                        ...payload
                    }
                }
            } else {
                parkingStorePrivate.ticketFormValidationStatus = null
            }
        }

        /**
         * Updates the payment information with the provided payload.
         *
         * @param payload - the new payment information
         * @return {type}
         */
        const updateTicketInfos = (payload) => {
            if (payload) {
                if (parkingStorePrivate.ticketInfos) {
                    parkingStorePrivate.ticketInfos = {
                        ...parkingStorePrivate.ticketInfos,
                        ...payload
                    }
                } else {
                    parkingStorePrivate.ticketInfos = {
                        ...payload
                    }
                }
            } else {
                parkingStorePrivate.ticketInfos = null
            }
        }

        /**
         * Updates the status object with the given value.
         *
         * @param {any} payload - The value to update the status object with.
         */
        const updateTicketStatus = (payload) => {
            if (payload) {
                if (parkingStorePrivate.ticketStatus) {
                    parkingStorePrivate.ticketStatus = {
                        ...parkingStorePrivate.ticketStatus,
                        ...payload
                    }
                } else {
                    parkingStorePrivate.ticketStatus = {
                        ...payload
                    }
                }
            } else {
                parkingStorePrivate.ticketStatus = null
            }
        }

        /**
         * Resets the parking store by updating various states related to parking and ticket information.
         *
         * This function performs the following actions:
         * - Hides the license plate.
         * - Resets the ticket payment progress to 0.
         * - Clears the ticket form values.
         * - Resets the ticket form validation status.
         * - Resets the ticket status.
         * - Sets the proceed payment status to false.
         * - Sets the payment success status to false.
         * - Sets the payment pending status to false.
         * - Clears the payment information.
         * - Clears the user voucher.
         */

        const resetParkingStore = () => {
            toggleLicensePlateVisibility(false)
            updateTicketPaymentProgress(0)
            updateTicketFormValues(null)
            updateTicketFormValidationStatus(null)
            updateTicketStatus(null)
            updateTicketInfos(null)
            updateTicketVoucher('')
        }

        /**
         * Handles the ticket validation form asynchronously.
         *
         * @param {Object} payload - The payload containing the ticket UID and center UID.
         */
        const handleGetTicketInfoSuccess = (payload) => {
            const { data, voucher = null, voucherElement = null } = payload

            // Reset voucher status
            updateTicketFormValidationStatus({ ['voucher']: '' })

            // Go to or stay on verify ticket form
            updateTicketPaymentProgress(1)

            // Handle results depending on voucher input
            if (voucher) {
                // Find the object with the specific code
                const usedVoucher = data?.voucherRates.find(item => item.code === voucher)

                // Check if the item is found and its status is "OK"
                if (usedVoucher && usedVoucher.status === 'OK') {
                    // Update the payment information
                    updateTicketInfos(data)

                    // Save valid user voucher code (in case user clicks back in progress bar
                    updateTicketVoucher(usedVoucher?.code)

                    // Set success status
                    updateTicketFormValidationStatus({
                        ['voucher']: 'success'
                    })
                } else {
                    // voucher was not accepted
                    updateTicketFormValidationStatus({
                        ['voucher']: 'error'
                    })
                }

                // Scroll to voucher section
                if (import.meta.client && voucherElement) {
                    window.scrollBy({
                        top: voucherElement.getBoundingClientRect().top + -120, // Adjust this value to your desired offset
                        behavior: 'smooth'
                    })
                }
            } else {
                // Update ticket infos in parking store
                updateTicketInfos(data)

                // Scroll on top of page
                if (import.meta.client) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            }
        }

        /**
         * Handles the ticket validation form asynchronously.
         *
         * @param {Object} payload - The payload containing the ticket UID and center UID.
         */
        const handleGetTicketInfoError = (payload) => {
            const { message, context } = payload

            // Handle (server) errors in a specific way (do not throw common error page)
            if (paymentProgress.value === '1') {
                updateTicketStatus({
                    success: false,
                    message: message
                })

                // Scroll on top of page
                if (import.meta.client) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            } else {
                if (context) {
                    updateTicketFormValidationStatus({
                        [context]: 'error'
                    })
                }
            }
        }

        /**
         *  Get notify response
         *
         * @param payload
         */
        const getNotifyResponse = async (payload) => {
            const { finalizeEndpointCorrelationId, transactionId, body } = payload

            // Set post request to finalize endpoint
            const { statusCode } = await $fetch(
                `/api/payment/transactions/${transactionId}/finalize`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'x-correlation-id': finalizeEndpointCorrelationId,
                    }
                }
            )

            console.log('getNotifyResponse statusCode', statusCode)

            return statusCode === 202
        }

        return {
            paymentProgress,
            savedTicketVoucher,
            ticketFormValidationStatus,
            ticketFormValues,
            isForeignLicensePlateVisible,
            ticketInfos,
            ticketStatus,
            toggleLicensePlateVisibility,
            updateTicketPaymentProgress,
            updateTicketFormValues,
            updateTicketFormValidationStatus,
            updateTicketStatus,
            updateTicketInfos,
            resetParkingStore,
            handleGetTicketInfoSuccess,
            handleGetTicketInfoError,
            getNotifyResponse,
        }
    }
)
