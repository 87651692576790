import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 51 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M46.2 1c1.8 0 2.7 1.2 2.7 3h-6c.5-2 1.7-3.1 3.3-3.1ZM51 5c0-3-1.6-5-4.6-5-1.7 0-3 .6-4 1.8A7.3 7.3 0 0 0 41 6.6c0 3.8 2.2 6.2 5.5 6.2 2.5 0 3.9-1.3 4.5-4l-.7-.3c-.6 1.5-1.8 2.2-3.5 2.2-2.4 0-4-1.8-4-4.8V5zm-15.4 7.8c2.4 0 4-1.3 4.5-4l-.7-.3a3.5 3.5 0 0 1-3.4 2.2c-2.5 0-4-1.8-4-4.9 0-3 1.6-4.8 4-4.8a3 3 0 0 1 2 .6l-.5.9a2 2 0 0 0-.4 1.1c0 .9.6 1.5 1.4 1.5 1 0 1.6-.8 1.6-2C40 1.4 38.4 0 36 0q-2.55 0-4.2 1.8a7 7 0 0 0-1.6 4.9c0 3.8 2 6.1 5.4 6.1M26.4 1c1.1 0 1.7.4 1.7 1l-.3 1-2.5 6.5-2.6-6.3-.2-1.1c0-.7.5-1 1.6-1.1V.3h-5.3V1c.7 0 1.1.5 1.8 2.3l3.7 8.7c-1.2 2.8-2 4.2-3 4.7-.8-.6-1.1-2-2.1-2-.8 0-1.4.6-1.4 1.4 0 1.2 1 1.9 2.4 1.9 2 0 3.4-1.4 4.8-5.1l4-9.7c.6-1.7 1-2.1 1.6-2.2V.3h-4.2zm-8.6 3.2q0-4.2-3.6-4.2-2.4 0-3.6 2.4C10 .8 9 0 7.3 0 5.8 0 4.6.8 3.7 2.5V.2h-.6L0 .9v.6c1.6 0 1.8.3 1.8 1.7V10c0 1.4-.2 1.8-1.8 1.9v.7h5.5v-.7c-.8 0-1.3-.2-1.5-.5-.2-.2-.3-.6-.3-1.4V3.4c.9-1.1 1.9-1.7 3-1.7 1.4 0 2.2 1 2.2 2.6v5.5q0 1.2-.3 1.5c-.2.3-.7.4-1.4.5v.7h5.3v-.7c-.8 0-1.3-.2-1.4-.5-.3-.2-.4-.7-.4-1.5V3.2c.8-1 1.8-1.5 2.9-1.5 1.5 0 2.3.9 2.3 2.6v5.5c0 .8 0 1.3-.3 1.5-.2.3-.7.4-1.4.5v.7h5.4v-.7c-.8 0-1.3-.2-1.5-.5q-.3-.3-.3-1.5z"
    }, null, -1)
  ])))
}
export default { render: render }