export const TRACKING_CONSTANTS = {
    LOAD_EVENTS: {
        PAGE_VIEW: 'page_view_meta',
        USER_INFO: 'user_info_meta'
    },
    CLICK_EVENTS: {
        APPSTORE_CLICK: 'Appstore_Click',
        CONTENT_TEASER_CLICK: 'Content_Teaser_Click',
        CENTER_CONTACT_CLICK: 'Center_Contact_Click',
        CENTER_SELECT: 'Center_Select',
        COUPON_CLICK: 'Coupon_Click',
        FAQ_CONTACT_CLICK: 'Faq_Contact_Click',
        QUESTION_CLICK: 'Question_Click',
        SHOP_CLICK: 'Shop_Click',
        SHOP_LOYALTY_REGISTER: 'Shop_Loyalty_Register',
        SHOP_CONTACT_CLICK: 'Shop_Contact_Click',
        PARKING_HOME_CLICK: 'Parking_Home_Click',
        PARKING_TICKET_PAYMENT_STEP1_CLICK: 'Parking_Ticketpayment_Step1_Click',
        PARKING_TICKET_PAYMENT_STEP2_CLICK: 'Parking_Ticketpayment_Step2_Click',
        PARKING_TICKET_PAYMENT_STEP3_CLICK: 'Parking_Ticketpayment_Step3_Click',
        USER_DELETE: 'User_Delete',
        USER_ONBOARDING: 'User_Onboarding',
        USER_PREFERENCES: 'User_Preferences',
    },
    TITLES: {
        CENTER_DETAILS: 'Center_Details',
        CENTER_SELECTION: 'Center_Selection',
        ACCOUNT_DELETE: 'Account_Delete',
        REGISTER_PREFERENCES: 'Register_Preferences',
        COOKIES_PREFERENCES_PAGE: 'Cookie_Preferences_Page',
        PARKING_HOME: 'Parking_Home',
        PARKING_TICKET_PAYMENT_STEP1: 'Parking_Ticket_Payment_Step1',
        PARKING_TICKET_PAYMENT_STEP2: 'Parking_Ticket_Payment_Step2',
        PARKING_TICKET_PAYMENT_STEP3: 'Parking_Ticket_Payment_Step3',
        PARKING_TICKET_PAYMENT_SUCCESS: 'Parking_Ticket_Payment_Success',
        PRIVACY_PAGE: 'Privacy_Page',
        FAQ_PAGE: 'Faq_Page',
        IMPRINT_PAGE: 'Imprint_Page',
        LEGAL_PAGE: 'Legal_Page',
        AGB_PAGE: 'Agb_Page',
        WELCOME_PAGE: 'Welcome_Page',
        HOME_PAGE: 'Home_Page',
        COUPONS_OVERVIEW: 'Coupons_Overview',
        SHOPS_OVERVIEW: 'Shops_Overview',
        CONTENT_DETAILS: 'Content_Details',
        SHOP_DETAILS: 'Shop_Details',
    },
    MISC: {
        KNOWN: 'known',
        EVENT: 'event',
        NEWS: 'news',
        HOME_PAGE: 'Home_Page',
        COUPONS_OVERVIEW: 'Coupons_Overview',
        CENTER_DETAILS: 'Center_Details',
        LOGGED_OUT: 'logged-out',
        LOGGED_IN: 'logged-in',
        CUSTOMER_SERVICE: 'Kundenservice',
        PARKING_HISTORY: 'Parkhistorie',
        PARKING_PAYMENT: 'Parkticket zahlen',
        PARKING_CREATE_PREPAID_CARD: 'Parkkarte Einrichten',
        STEP_ID_ONBOARDING: '6',
        STEP_ACTION_ONBOARDING: 'proceed',
        FAQ: 'FAQ',
        FAQ_CONTACT_CLICK: 'true',
        INFOS: 'Infos',
    }
}
