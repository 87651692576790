/**
 * Converts a query parameters to content mapping object into a configuration object.
 *
 * @param {Object} queryParamsToContentMap - The mapping of query parameters to content configuration.
 * @return {Object} The content configuration object.
 */
const getMappedContentConfig = (queryParamsToContentMap) => {
    return Object.fromEntries(
        Object.entries(queryParamsToContentMap).map(([key, value]) => [
            key,
            {
                open: true,
                content: value.content,
                sideBarType: value.sideBarType,
                triggerConsent: value.triggerConsent,
                auth: value.auth,
            },
        ])
    )
}

/**
 * Utility function to get keys from the queryParamsToContentMap based on a specified condition.
 *
 * @param {Object} queryParamsToContentMap - The mapping of query parameters to content.
 * @param {string} conditionKey - The condition key to check (e.g., 'requiresCenterPage' or 'auth').
 * @return {Array<string>} - Returns an array of keys that meet the condition.
 */
const getKeysByCondition = (queryParamsToContentMap, conditionKey) => {
    return Object.entries(queryParamsToContentMap)
        .filter(([_, value]) => value[conditionKey])
        .map(([key]) => key)
}

/**
 * Creates a cookie with the given options.
 *
 * @param {Object} payload - Options for the cookie.
 * @param {boolean} [payload.httpOnly=false] - Indicates whether the cookie is HTTP-only.
 * @param {Date} [payload.expires=null] - The expiration date for the cookie.
 * @returns {{path: string, sameSite: string, httpOnly: boolean, secure: boolean, expires: Date}} - Cookie options.
 */
const getCookieOptions = ({ httpOnly = false, expires = null } = {}) => {
    return {
        path: '/',
        sameSite: 'strict',
        secure: true,
        httpOnly,
        expires
    }
}

export {
    getMappedContentConfig,
    getKeysByCondition,
    getCookieOptions,
}
