/**
 * Generates an array of placeholder shop list items.
 *
 * @param {number} length - The length of the array to generate.
 * @return {Array} An array of placeholder shop list items.
 */
const generatePlaceholderShopList = (length) => {
    return Array.from({ length }, (_, index) => {
        const hasLoyaltyProgram = index < length - 2 // Set to true for all items except last 2

        return {
            type: Math.random() < 0.5 ? 'gastro' : 'shop', // Randomly set to 'gastro' or 'shop'
            name: '',
            hasLoyaltyProgram,
            hasCoupons: Math.random() < 0.5,
            loyaltyProgram: hasLoyaltyProgram ? {} : undefined
        }
    })
}

const shopListPlaceHolderData = {
    A: generatePlaceholderShopList(5),
    B: generatePlaceholderShopList(6),
    C: generatePlaceholderShopList(5)
}

const centerListPlaceHolderData = Array.from({ length: 3 }, (_, index) => ({
    ...{
        uid: index,
        name: 'name',
        logotype: {
            url: 'url'
        }
    },
    uid: `center-list-placeholder-${index}` // Unique placeholder ID
}))

const savedCreditCardsPlaceHolderData = Array.from({ length: 1 }, (_, index) => ({
    ...{
        cardOwner: 'cardOwner',
        cardNumber: 'cardNumber',
        expiryDate: 'expiryDate'
    },
}))

/**
 * Placeholder data for news and events
 * @type {{teaser: {dateText: string, uid: string, "myce-exclusive": boolean, timeText: string, imageUrl: string, location: string, type: string, title: string}}[]}
 */
const newsAndEventsPlaceHolderData = Array.from({ length: 6 }, (_, index) => ({
    teaser: {
        'uid': index + 1, // Unique ID for each placeholder
        'type': index % 2 === 0 ? 'event' : 'news', // Alternate between 'event' and 'news'
        'title': `Placeholder Title ${index + 1}`, // Example title
        'location': `Location ${index + 1}`, // Placeholder location
        'dateText': `Date ${index + 1}`, // Placeholder date text
        'timeText': `Time ${index + 1}`, // Placeholder time text
        'imageUrl': `https://placeholder.com/image${index + 1}`, // Placeholder image URL
        'myce-exclusive': Math.random() < 0.5 // Randomly set to true or false
    }
}))

const couponListPlaceHolderData = Array.from({ length: 6 }, (_, index) => ({
    uid: `coupon-${index}`
}))

/**
 *  Placeholder data for loyalty programs
 * @type {{}}
 */
const shopLoyaltyPlaceHolderData = {}

const shopDetailPlaceHolderData = {}
const centerDetailsPlaceHolderData = {}

const faqPlaceHolderData = {
    faq: Array.from({ length: 5 }, (_, i) => ({
        question: `Question ${i + 1}`,
        answer: `Answer ${i + 1}`
    }))
}

const legalPlaceHolderData = {}

const parkingPaymentHistoryPlaceHolderData = []

const smartTransactionPlaceHolderData = {}

export {
    shopListPlaceHolderData,
    centerListPlaceHolderData,
    savedCreditCardsPlaceHolderData,
    newsAndEventsPlaceHolderData,
    couponListPlaceHolderData,
    shopLoyaltyPlaceHolderData,
    shopDetailPlaceHolderData,
    centerDetailsPlaceHolderData,
    faqPlaceHolderData,
    legalPlaceHolderData,
    parkingPaymentHistoryPlaceHolderData,
    smartTransactionPlaceHolderData
}
