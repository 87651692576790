import { storeToRefs } from 'pinia'
import { acquireTokenSilent } from '~/composables/useMsal'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
    // Exclude web views from global auth middleware
    if (to.meta?.webview) return

    // Auth Store
    const authStore = useAuthStore()
    const { updateLoggedInStatus, handleOnboarding } = authStore
    const { isLoggedIn } = storeToRefs(authStore)

    // Extract bearer token from cookie (set after azure-login)
    const cookieOptions = getCookieOptions()
    const authCookie = useCookie('auth-cookie', cookieOptions)

    const bearerToken = authCookie?.value?.bearerToken

    if (bearerToken) {
        // We have stored token in cookie, so lets check on every request if it is still valid
        try {
            const { oid, isValid } = await $fetch('/api/validate-token', {
                headers: {
                    Authorization: bearerToken
                }
            })

            // Proceed if token is valid
            if (isValid) {
                // Check if user is logged in, if not then set some auth states
                if (!isLoggedIn.value) {
                    updateLoggedInStatus(true)

                    useState('state_bearerToken', () => bearerToken)
                    useState('state_userOid', () => oid)
                    useState('state_showOnboarding', () => !to?.meta?.ignoreOnBoarding)
                }
            } else {
                // If Token is not valid anymore, try silent login
                await acquireTokenSilent()

                console.log('Token is not valid anymore, try silent login')
            }
        } catch (error) {
            // If there is error in auth middleware, try silent login
            await acquireTokenSilent()

            console.log('Error in auth middleware ', error)
        }
    }
})
