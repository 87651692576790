import { TRACKING_CONSTANTS } from '@/utils/trackingConstants'

/**
 * Adapts domain-specific user click data into the format required by the tracking provider.
 *
 * This function transforms a given domain data object into a provider-specific format.
 * Any properties that are undefined in the input will be omitted from the returned object.
 *
 * @param {Object} domainData - The domain-specific data for tracking user clicks.
 * @param {string} [domainData.eventName] - The event name associated with the user click.
 * @param {string} [domainData.elementContext] - The context in which the element was clicked.
 * @param {string} [domainData.appProvider] - The app provider related to the click event.
 * @param {string} [domainData.centerUid] - The unique identifier of the center.
 * @param {string} [domainData.centerName] - The name of the center.
 * @param {string} [domainData.contentTitle] - The title of the related content.
 * @param {string} [domainData.contentType] - The type/category of the content.
 * @param {string} [domainData.screenName] - The name of the screen where the click occurred.
 * @param {string} [domainData.contactType] - The type of contact interaction.
 * @param {string} [domainData.centerPreference] - The user's preferred center.
 * @param {string} [domainData.couponId] - The identifier of the coupon (if applicable).
 * @param {string} [domainData.shopId] - The identifier of the shop (if applicable).
 * @param {string} [domainData.shopName] - The name of the shop (if applicable).
 * @param {string} [domainData.couponTitle] - The title of the coupon.
 * @param {string} [domainData.couponsValidity] - The validity period of the coupon.
 * @param {boolean} [domainData.clicked] - Whether the item was clicked.
 * @param {string} [domainData.questionText] - The text of a question (if applicable).
 * @param {string} [domainData.button] - The button label that was clicked.
 * @param {string} [domainData.voucher] - The voucher associated with the click event.
 * @param {string} [domainData.stepId] - The identifier for the step in a multi-step process.
 * @param {string} [domainData.stepAction] - The action performed in the current step.
 * @param {Object} [domainData.registerFormUserData] - Data entered by the user in a registration form.
 * @returns {Object} An object formatted for the tracking provider with only defined properties, including:
 *   - event: {string} The tracking event name.
 *   - element_context: {string} The context of the clicked element.
 *   - app_provider: {string} The related app provider.
 *   - center_id: {string} The center's unique identifier.
 *   - center_name: {string} The center's name.
 *   - content_title: {string} The content's title.
 *   - content_type: {string} The content type.
 *   - screen_name: {string} The screen where the click occurred.
 *   - contact_type: {string} The type of contact interaction.
 *   - center_preference: {string} The user's center preference.
 *   - coupon_id: {string} The coupon identifier.
 *   - shop_id: {string} The shop identifier.
 *   - shop_name: {string} The shop name.
 *   - coupon_title: {string} The coupon's title.
 *   - coupon_validity: {string} The validity of the coupon.
 *   - clicked: {boolean} Whether the item was clicked.
 *   - question_text: {string} The text of a question.
 *   - button: {string} The button label.
 *   - voucher: {string} The associated voucher.
 *   - step_id: {string} The step identifier.
 *   - step_action: {string} The action performed in the step.
 *   - register_form_user_data: {Object} The user data from a registration form.
 */
const adaptUserClickForGTM = (domainData) => {
    const {
        eventName,
        elementContext,
        appProvider,
        centerUid,
        centerName,
        contentTitle,
        contentType,
        screenName,
        contactType,
        centerPreference,
        couponId,
        shopId,
        shopName,
        couponTitle,
        couponsValidity,
        clicked,
        questionText,
        button,
        voucher,
        stepId,
        stepAction,
        registerFormUserData
    } = domainData

    // Create the object and remove undefined values
    return Object.fromEntries(
        Object.entries({
            event: eventName,
            element_context: elementContext,
            app_provider: appProvider,
            center_id: centerUid,
            center_name: centerName,
            content_title: contentTitle,
            content_type: contentType,
            screen_name: screenName,
            contact_type: contactType,
            center_preference: centerPreference,
            coupon_id: couponId,
            shop_id: shopId,
            shop_name: shopName,
            coupon_title: couponTitle,
            coupon_validity: couponsValidity,
            clicked,
            question_text: questionText,
            button,
            voucher,
            step_id: stepId,
            step_action: stepAction,
            register_form_user_data: registerFormUserData
        }).filter(([_, value]) => value !== undefined)
    )
}

/**
 * Adapts domain-specific page load data into the format required by the tracking provider.
 *
 * This function maps a given domain data object to a provider-specific object. Any properties
 * that are undefined in the input will be omitted from the returned object.
 *
 * @param {Object} domainData - The domain-specific data for page load tracking.
 * @param {string} [domainData.eventName=TRACKING_CONSTANTS.LOAD_EVENTS.PAGE_VIEW] - The event name for the page load event.
 * @param {string} [domainData.title] - The custom title for the page.
 * @param {string} [domainData.centerUid] - The unique identifier of the center.
 * @param {string} [domainData.centerName] - The name of the center.
 * @param {string} [domainData.loginStatus] - The login status of the user (if applicable).
 * @param {string} [domainData.contentTitle] - The title of the content being viewed.
 * @param {string} [domainData.contentType] - The type/category of the content.
 * @param {string} [domainData.shopName] - The name of the shop (if applicable).
 * @returns {Object} An object formatted for the tracking provider with only defined properties, including:
 *   - event: {string} The tracking event name.
 *   - custom_page_title: {string} The page title.
 *   - center_id: {string} The center's unique identifier.
 *   - center_name: {string} The center's name.
 *   - login_status: {string} The user's login status.
 *   - content_title: {string} The content's title.
 *   - content_type: {string} The content type.
 *   - shop_name: {string} The shop's name.
 */
const adaptPageLoadForGTM = (domainData) => {
    const {
        eventName = TRACKING_CONSTANTS.LOAD_EVENTS.PAGE_VIEW,
        title,
        centerUid,
        centerName,
        loginStatus,
        contentTitle,
        contentType,
        shopName
    } = domainData

    // Create the object and remove undefined values
    return Object.fromEntries(
        Object.entries({
            event: eventName,
            custom_page_title: title,
            center_id: centerUid,
            center_name: centerName,
            login_status: loginStatus,
            content_title: contentTitle,
            content_type: contentType,
            shop_name: shopName,
        }).filter(([_, value]) => value !== undefined)
    )
}

/**
 * Adapts domain-specific user metadata to the format required by the tracking provider.
 *
 * @param {Object} domainData - The domain-specific data for user meta tracking.
 * @param {string} [domainData.eventName=TRACKING_CONSTANTS.LOAD_EVENTS.USER_INFO] - The event name for the tracking event.
 * @param {string} domainData.userId - The unique identifier of the user.
 * @param {string} [domainData.userType=TRACKING_CONSTANTS.MISC.KNOWN] - The type/category of the user.
 * @returns {Object} An object formatted for the tracking provider, containing:
 *   @property {string} event - The tracking event name.
 *   @property {string} user_id - The user's unique identifier.
 *   @property {string} user_type - The user's type.
 */
const adaptUserMetaForGTM = (domainData) => {
    const {
        eventName = TRACKING_CONSTANTS.LOAD_EVENTS.USER_INFO,
        userId,
        userType = TRACKING_CONSTANTS.MISC.KNOWN
    } = domainData

    return {
        event: eventName,
        user_id: userId,
        user_type: userType
    }
}

export {
    adaptUserClickForGTM,
    adaptPageLoadForGTM,
    adaptUserMetaForGTM
}
