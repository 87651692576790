import * as msal from '@azure/msal-browser'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin(async () => {
    const authStore = useAuthStore()
    const { updateLoggedInStatus } = authStore

    const config = useRuntimeConfig()

    const route = useRoute()

    const msalConfig = {
        auth: {
            clientId: config.public.azureAdClientId, // Details: App ID of your application. Can be found in your portal registration.
            authority: `https://${config.public.azureAdUrl}.b2clogin.com/${config.public.azureAdUrl}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`, // URI of the tenant to authenticate and authorize with. Usually takes the form of https://{uri}/{tenantid} (see Authority)
            knownAuthorities: [`${config.public.azureAdUrl}.b2clogin.com`], // An array of URIs that are known to be valid. Used in B2C scenarios.
            redirectUri: '/auth', // URI where the authorization code response is sent back to. Whatever location is specified here must have the MSAL library available to handle the response.
            postLogoutRedirectUri: window.location.href
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
            secureCookies: true
        },
        telemetry: {
            application: {
                appName: 'MYCE',
                appVersion: '1.0.0'
            }
        }
    }

    // Creating msal instance
    const msalInstance = new msal.PublicClientApplication(msalConfig)

    // Check if account exists
    if (msalInstance.getActiveAccount() === null) {
        updateLoggedInStatus(false)
    }

    /**
     * Initializes the MSAL instance and handles the redirect promise.
     *
     * @param {msalInstance} msalInstance - The MSAL instance to initialize and handle the redirect promise.
     * @return {Promise} A promise that resolves when the redirect promise is handled.
     */

    const initializeMsalAndHandleRedirect = async (msalInstance) => {
        await msalInstance.initialize()
        return await msalInstance.handleRedirectPromise()
    }

    /**
     * Sets the authentication cookie and state variables.
     *
     * @param {string} bearerToken - The bearer token used for authentication.
     * @param {string} oid - The user OID.
     * @param {boolean} ignoreOnBoarding - A flag indicating whether to show onboarding.
     * @return {object} An object containing the state variables useStateBearerToken and useStateUserOid.
     */
    const setAuthCookieAndState = (bearerToken, oid, ignoreOnBoarding) => {
        const authCookieOptions = getCookieOptions()
        const authCookie = useCookie('auth-cookie', authCookieOptions)

        authCookie.value = {
            bearerToken
        }

        const useStateBearerToken = useState('state_bearerToken', () => bearerToken)
        const useStateUserOid = useState('state_userOid', () => oid)
        const useStateOnboarding = useState('state_showOnboarding', () => !ignoreOnBoarding)

        return { useStateBearerToken, useStateUserOid, useStateOnboarding }
    }

    /**
     * Processes user login using the provided `msalInstance`.
     *
     * @param {Object} msalInstance - The MSAL instance used for authentication.
     * @param {Function} updateLoggedInStatus - A function to update the logged in status.
     * @param {Function} trackUserMeta - A function to track user metadata.
     * @return {Promise<void>} A Promise that resolves when user login is processed.
     */
    const processUserLogin = async (msalInstance, updateLoggedInStatus, trackUserMeta) => {
        try {
            const response = await initializeMsalAndHandleRedirect(msalInstance)

            if (!response) return

            const accounts = msalInstance?.getAllAccounts()

            if (accounts?.length === 0) return

            msalInstance.setActiveAccount(accounts[0])

            const bearerToken = response.accessToken
            const oid = response?.account?.idTokenClaims?.oid
            const ignoreOnBoarding = response?.state === 'ignoreOnBoarding'

            setAuthCookieAndState(bearerToken, oid, ignoreOnBoarding)

            updateLoggedInStatus(true)

            trackUserMeta({ userId: oid })
        } catch (err) {
            console.error(`Auth Plugin MSAL handleRedirectPromise error: ${err}`)
            // Handle the error appropriately, e.g., show a user-friendly message or log it.
        }
    }

    // Usage
    await processUserLogin(msalInstance, updateLoggedInStatus, trackUserMeta)

    return {
        provide: {
            msal: msalInstance
        }
    }
})
