/**
 * Validates whether the given date string conforms to the expected YYYY.MM.DD format.
 * An empty string or undefined value is considered valid.
 *
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} Returns true if the dateString is empty, undefined, or matches the format "YYYY.MM.DD"; otherwise, false.
 */
const checkDateFormat = (dateString) => {
    if (dateString === '' || dateString === undefined) {
        return true
    }

    const regex = /^[0-9]{4}.(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/
    return regex.test(dateString)
}

/**
 * Checks if a customer meets the minimum age requirement based on their date of birth.
 * An empty dateOfBirth string is considered valid.
 *
 * @param {string} dateOfBirth - The customer's date of birth in a format recognized by the Date constructor.
 * @returns {boolean} Returns true if the customer is at least 16 years old or if dateOfBirth is empty; otherwise, false.
 */
const checkCustomerAge = (dateOfBirth) => {
    if (dateOfBirth === '') {
        return true
    }

    const MINIMUM_AGE = 16
    const birthDate = new Date(dateOfBirth)
    const currentDate = new Date()
    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear()

    if (yearsDiff < MINIMUM_AGE) {
        return false
    } else if (yearsDiff === MINIMUM_AGE) {
        const currentMonth = currentDate.getMonth()
        const birthMonth = birthDate.getMonth()

        if (currentMonth < birthMonth) {
            return false
        } else if (currentMonth === birthMonth) {
            const currentDay = currentDate.getDate()
            const birthDay = birthDate.getDate()

            if (currentDay < birthDay) {
                return false
            }
        }
    }

    return true
}

export {
    checkCustomerAge,
    checkDateFormat
}
