/**
 * Generates a formatted email link object.
 *
 * @param {string} email - The email address to format.
 * @return {Object|boolean} An object with label and mailto link, or false if email is empty.
 */
const getFormattedEmailLink = (email) => {
    if (!email) {
        return false
    }

    return {
        label: email,
        link: `mailto: ${email}`
    }
}

/**
 * Generates a formatted phone link object.
 *
 * @param {string} phone - The phone number to format.
 * @return {Object|boolean} An object with label and tel link, or false if phone is empty.
 */
const getFormattedPhoneLink = (phone) => {
    if (!phone) {
        return false
    }

    return {
        label: phone,
        link: `tel: ${phone.replaceAll(' ', '').replaceAll('-', '')}`
    }
}

/**
 * Generates a formatted website link object.
 *
 * @param {string} url - The website URL.
 * @param {string} [label=url] - The label for the link (defaults to URL).
 * @return {Object|boolean} An object with label and link, or false if URL is empty.
 */
const getFormattedWebsiteLink = (url, label = url) => {
    if (!url) {
        return false
    }

    return {
        label,
        link: url
    }
}

/**
 * Formats a date string into "dd.mm.yyyy".
 *
 * @param {string} inputDateString - The input date string.
 * @return {string} The formatted date or an empty string if input is invalid.
 */
const getFormattedDate = (inputDateString) => {
    if (!inputDateString) {
        return ''
    }

    const inputDate = new Date(inputDateString)

    const day = String(inputDate.getDate()).padStart(2, '0')
    const month = String(inputDate.getMonth() + 1).padStart(2, '0') // Month is 0-based, so we add 1.
    const year = inputDate.getFullYear()

    return `${day}.${month}.${year}`
}

/**
 * Formats a number as a currency string.
 *
 * @param {number|string} price - The price value to format.
 * @param {string} currency - The currency code (e.g., "EUR", "USD").
 * @return {string} The formatted price string or an empty string if input is invalid.
 */
const getFormattedPrice = (price, currency) => {
    if (price === null || price === undefined) {
        return ''
    }

    return price.toLocaleString('de-DE', { style: 'currency', currency: currency })
}

/**
 * Truncates the last item in an unordered list within an HTML string.
 *
 * @param {string} htmlString - The input HTML string containing a <ul>.
 * @return {string} The modified HTML string with the last <li> truncated or unchanged if no list is found.
 */
const getFormattedList = (htmlString) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString

    const list = tempElement.querySelector('ul')
    const lastLi = list?.lastElementChild

    if (lastLi) {
        const text = lastLi.textContent.trim()
        const lastWordIndex = text.lastIndexOf(' ')
        const newText = text.substring(0, lastWordIndex)

        lastLi.textContent = newText + '...'

        if (lastLi.textContent === '...') {
            list.removeChild(lastLi)
        }

        return tempElement.innerHTML
    }

    return htmlString
}

/**
 * Generates a formatted tracking data string from form values.
 *
 * @param {Object} formValues - An object containing form values.
 * @return {string} A pipe-separated string of sorted form values.
 */
const getFormattedTrackingUserData = (formValues) => {
    const objContainsValues = (obj) => {
        return Object.values(obj).some(value => value && value !== '' && value?.length !== 0)
    }

    const formObj = Object.entries(formValues)
        .map(([key, value]) => ({ [key]: value || '' }))
        .filter(objContainsValues)

    const keyOrder = ['lastName', 'firstname', 'gender', 'birthday', 'zip', 'preferences']

    const sortedObj = sortObjects(formObj, keyOrder)

    return sortedObj.map(obj => Object.values(obj)).join('|')
}

/**
 * Transforms form values into a structured user data object.
 *
 * @param {Object} values - The input form values object.
 * @return {Object} The transformed user data object with structured fields.
 */
const getFormattedFormUserData = (values) => {
    return Object.keys(values).reduce((acc, key) => {
        if (key === 'postalCode') {
            acc.billingAddress = { postalCode: values[key] === '' ? null : values[key] }
        } else if (key === 'preferences') {
            if (values[key].length > 0) {
                acc.preferences = { interests: values[key] }
            }
        } else {
            acc[key] = values[key] === '' ? null : values[key]
        }

        return acc
    }, {})
}

export {
    getFormattedEmailLink,
    getFormattedPhoneLink,
    getFormattedWebsiteLink,
    getFormattedDate,
    getFormattedPrice,
    getFormattedList,
    getFormattedTrackingUserData,
    getFormattedFormUserData
}
