export default function useDeleteProfileMutation(payload) {
    const {
        bearerToken,
        oid,
        onSuccessCallback,
        onErrorCallback,
    } = payload

    const headers = {
        'Authorization': `Bearer ${bearerToken.value}`,
        'User-Oid': oid.value,
    }

    const apiUrl = '/api/consumers/profiles/deleteProfile'
    const mutationKey = ['profileDelete', oid.value]
    const method = 'DELETE'

    return useTanstackMutation({
        mutationKey,
        method,
        apiUrl,
        headers,
        onSuccessCallback,
        onErrorCallback
    })
}
