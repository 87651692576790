
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexkXuDfUHWgRMeta } from "/usr/src/app/src/pages/[centerUid]/coupons/index.vue?macro=true";
import { default as dataProtection7KammCymnlMeta } from "/usr/src/app/src/pages/[centerUid]/dataProtection.vue?macro=true";
import { default as _91eventsUid_93eJDj1QKXkJMeta } from "/usr/src/app/src/pages/[centerUid]/events/[eventsUid].vue?macro=true";
import { default as faqX33KzeauyVMeta } from "/usr/src/app/src/pages/[centerUid]/faq.vue?macro=true";
import { default as imprintigY7LruILmMeta } from "/usr/src/app/src/pages/[centerUid]/imprint.vue?macro=true";
import { default as indexXPHnPKceixMeta } from "/usr/src/app/src/pages/[centerUid]/index.vue?macro=true";
import { default as legalOpPjvtYIZbMeta } from "/usr/src/app/src/pages/[centerUid]/legal.vue?macro=true";
import { default as _91newsUid_93mamtmETrDFMeta } from "/usr/src/app/src/pages/[centerUid]/news/[newsUid].vue?macro=true";
import { default as indexKFZjmzHCFsMeta } from "/usr/src/app/src/pages/[centerUid]/parking/index.vue?macro=true";
import { default as history89aFk87qTFMeta } from "/usr/src/app/src/pages/[centerUid]/parking/payment/history.vue?macro=true";
import { default as payTicketZNVZWQbinqMeta } from "/usr/src/app/src/pages/[centerUid]/parking/payTicket.vue?macro=true";
import { default as parking7N15MSkyy1Meta } from "/usr/src/app/src/pages/[centerUid]/parking.vue?macro=true";
import { default as couponsaoDKvWh8J3Meta } from "/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/coupons.vue?macro=true";
import { default as indexPedeY9O0SKMeta } from "/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/index.vue?macro=true";
import { default as indexgbUFBmaX2IMeta } from "/usr/src/app/src/pages/[centerUid]/shops/index.vue?macro=true";
import { default as shops1eUwtwqQCGMeta } from "/usr/src/app/src/pages/[centerUid]/shops.vue?macro=true";
import { default as termscfAsrHfiYAMeta } from "/usr/src/app/src/pages/[centerUid]/terms.vue?macro=true";
import { default as authTJEXAOe4vXMeta } from "/usr/src/app/src/pages/auth.vue?macro=true";
import { default as dataProtection4xyg8OERuJMeta } from "/usr/src/app/src/pages/dataProtection.vue?macro=true";
import { default as faqlAaQg9OQGTMeta } from "/usr/src/app/src/pages/faq.vue?macro=true";
import { default as i18nHvr31YX0URMeta } from "/usr/src/app/src/pages/i18n.vue?macro=true";
import { default as imprint3HHIDK07CVMeta } from "/usr/src/app/src/pages/imprint.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as legalWk0AEMI8lGMeta } from "/usr/src/app/src/pages/legal.vue?macro=true";
import { default as logoutAhPWW22ABKMeta } from "/usr/src/app/src/pages/logout.vue?macro=true";
import { default as failuretSqLeewcJZMeta } from "/usr/src/app/src/pages/payment/failure.vue?macro=true";
import { default as redirectY9j9fJdMrkMeta } from "/usr/src/app/src/pages/payment/redirect.vue?macro=true";
import { default as successAB6MazCFc7Meta } from "/usr/src/app/src/pages/payment/success.vue?macro=true";
import { default as termsA1HF1pYOfSMeta } from "/usr/src/app/src/pages/terms.vue?macro=true";
import { default as testk3FxbpXxpUMeta } from "/usr/src/app/src/pages/test.vue?macro=true";
import { default as uiBlCNHk5sa8Meta } from "/usr/src/app/src/pages/ui.vue?macro=true";
import { default as unsubscribe_45errorUd71VnFZyOMeta } from "/usr/src/app/src/pages/unsubscribe-error.vue?macro=true";
import { default as unsubscribe_45noaccountpCfjiofP00Meta } from "/usr/src/app/src/pages/unsubscribe-noaccount.vue?macro=true";
import { default as unsubscribe_45successiiKViE9bsuMeta } from "/usr/src/app/src/pages/unsubscribe-success.vue?macro=true";
import { default as parking_45payment_45historyqbwSwRzxyYMeta } from "/usr/src/app/src/pages/webviews/parking-payment-history.vue?macro=true";
import { default as _91transactionId_93TKz3Unac6hMeta } from "/usr/src/app/src/pages/webviews/payment/[transactionId].vue?macro=true";
import { default as personal_45databIICo6LiXGMeta } from "/usr/src/app/src/pages/webviews/personal-data.vue?macro=true";
import { default as personal_45preferences7KQ2cTr08HMeta } from "/usr/src/app/src/pages/webviews/personal-preferences.vue?macro=true";
import { default as component_45stubuYxk0V0rW5Meta } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuYxk0V0rW5 } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "centerUid-coupons___de",
    path: "/:centerUid()/coupons",
    meta: indexkXuDfUHWgRMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/coupons/index.vue")
  },
  {
    name: "centerUid-coupons___en",
    path: "/en/:centerUid()/coupons",
    meta: indexkXuDfUHWgRMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/coupons/index.vue")
  },
  {
    name: "centerUid-dataProtection___de",
    path: "/:centerUid()/datenschutz",
    component: () => import("/usr/src/app/src/pages/[centerUid]/dataProtection.vue")
  },
  {
    name: "centerUid-dataProtection___en",
    path: "/en/:centerUid()/data-protection",
    component: () => import("/usr/src/app/src/pages/[centerUid]/dataProtection.vue")
  },
  {
    name: "centerUid-events-eventsUid___de",
    path: "/:centerUid()/events/:eventsUid()",
    component: () => import("/usr/src/app/src/pages/[centerUid]/events/[eventsUid].vue")
  },
  {
    name: "centerUid-events-eventsUid___en",
    path: "/en/:centerUid()/events/:eventsUid()",
    component: () => import("/usr/src/app/src/pages/[centerUid]/events/[eventsUid].vue")
  },
  {
    name: "centerUid-faq___de",
    path: "/:centerUid()/faq",
    component: () => import("/usr/src/app/src/pages/[centerUid]/faq.vue")
  },
  {
    name: "centerUid-faq___en",
    path: "/en/:centerUid()/faq",
    component: () => import("/usr/src/app/src/pages/[centerUid]/faq.vue")
  },
  {
    name: "centerUid-imprint___de",
    path: "/:centerUid()/impressum",
    component: () => import("/usr/src/app/src/pages/[centerUid]/imprint.vue")
  },
  {
    name: "centerUid-imprint___en",
    path: "/en/:centerUid()/imprint",
    component: () => import("/usr/src/app/src/pages/[centerUid]/imprint.vue")
  },
  {
    name: "centerUid___de",
    path: "/:centerUid()",
    meta: indexXPHnPKceixMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/index.vue")
  },
  {
    name: "centerUid___en",
    path: "/en/:centerUid()",
    meta: indexXPHnPKceixMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/index.vue")
  },
  {
    name: "centerUid-legal___de",
    path: "/:centerUid()/rechtliches",
    component: () => import("/usr/src/app/src/pages/[centerUid]/legal.vue")
  },
  {
    name: "centerUid-legal___en",
    path: "/en/:centerUid()/legal",
    component: () => import("/usr/src/app/src/pages/[centerUid]/legal.vue")
  },
  {
    name: "centerUid-news-newsUid___de",
    path: "/:centerUid()/news/:newsUid()",
    component: () => import("/usr/src/app/src/pages/[centerUid]/news/[newsUid].vue")
  },
  {
    name: "centerUid-news-newsUid___en",
    path: "/en/:centerUid()/news/:newsUid()",
    component: () => import("/usr/src/app/src/pages/[centerUid]/news/[newsUid].vue")
  },
  {
    name: parking7N15MSkyy1Meta?.name,
    path: "/:centerUid()/parken",
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking.vue"),
    children: [
  {
    name: "centerUid-parking___de",
    path: "",
    meta: indexKFZjmzHCFsMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/index.vue")
  },
  {
    name: "centerUid-parking-payment-history___de",
    path: "bezahlhistorie",
    meta: history89aFk87qTFMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/payment/history.vue")
  },
  {
    name: "centerUid-parking-payTicket___de",
    path: "ticket-bezahlen",
    meta: payTicketZNVZWQbinqMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/payTicket.vue")
  }
]
  },
  {
    name: parking7N15MSkyy1Meta?.name,
    path: "/en/:centerUid()/parking",
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking.vue"),
    children: [
  {
    name: "centerUid-parking___en",
    path: "",
    meta: indexKFZjmzHCFsMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/index.vue")
  },
  {
    name: "centerUid-parking-payment-history___en",
    path: "payment-history",
    meta: history89aFk87qTFMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/payment/history.vue")
  },
  {
    name: "centerUid-parking-payTicket___en",
    path: "pay-ticket",
    meta: payTicketZNVZWQbinqMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/parking/payTicket.vue")
  }
]
  },
  {
    name: shops1eUwtwqQCGMeta?.name,
    path: "/:centerUid()/shops",
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops.vue"),
    children: [
  {
    name: "centerUid-shops-shopUid-coupons___de",
    path: ":shopUid()/coupons",
    meta: couponsaoDKvWh8J3Meta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/coupons.vue")
  },
  {
    name: "centerUid-shops-shopUid___de",
    path: ":shopUid()",
    meta: indexPedeY9O0SKMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/index.vue")
  },
  {
    name: "centerUid-shops___de",
    path: "",
    meta: indexgbUFBmaX2IMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/index.vue")
  }
]
  },
  {
    name: shops1eUwtwqQCGMeta?.name,
    path: "/en/:centerUid()/shops",
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops.vue"),
    children: [
  {
    name: "centerUid-shops-shopUid-coupons___en",
    path: ":shopUid()/coupons",
    meta: couponsaoDKvWh8J3Meta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/coupons.vue")
  },
  {
    name: "centerUid-shops-shopUid___en",
    path: ":shopUid()",
    meta: indexPedeY9O0SKMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/[shopUid]/index.vue")
  },
  {
    name: "centerUid-shops___en",
    path: "",
    meta: indexgbUFBmaX2IMeta || {},
    component: () => import("/usr/src/app/src/pages/[centerUid]/shops/index.vue")
  }
]
  },
  {
    name: "centerUid-terms___de",
    path: "/:centerUid()/agb",
    component: () => import("/usr/src/app/src/pages/[centerUid]/terms.vue")
  },
  {
    name: "centerUid-terms___en",
    path: "/en/:centerUid()/terms",
    component: () => import("/usr/src/app/src/pages/[centerUid]/terms.vue")
  },
  {
    name: "auth___de",
    path: "/auth",
    meta: authTJEXAOe4vXMeta || {},
    component: () => import("/usr/src/app/src/pages/auth.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    meta: authTJEXAOe4vXMeta || {},
    component: () => import("/usr/src/app/src/pages/auth.vue")
  },
  {
    name: "dataProtection___de",
    path: "/datenschutz",
    meta: dataProtection4xyg8OERuJMeta || {},
    component: () => import("/usr/src/app/src/pages/dataProtection.vue")
  },
  {
    name: "dataProtection___en",
    path: "/en/data-protection",
    meta: dataProtection4xyg8OERuJMeta || {},
    component: () => import("/usr/src/app/src/pages/dataProtection.vue")
  },
  {
    name: "faq___de",
    path: "/faq",
    meta: faqlAaQg9OQGTMeta || {},
    component: () => import("/usr/src/app/src/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    meta: faqlAaQg9OQGTMeta || {},
    component: () => import("/usr/src/app/src/pages/faq.vue")
  },
  {
    name: "i18n___de",
    path: "/i18n",
    meta: i18nHvr31YX0URMeta || {},
    component: () => import("/usr/src/app/src/pages/i18n.vue")
  },
  {
    name: "i18n___en",
    path: "/en/i18n",
    meta: i18nHvr31YX0URMeta || {},
    component: () => import("/usr/src/app/src/pages/i18n.vue")
  },
  {
    name: "imprint___de",
    path: "/impressum",
    meta: imprint3HHIDK07CVMeta || {},
    component: () => import("/usr/src/app/src/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    meta: imprint3HHIDK07CVMeta || {},
    component: () => import("/usr/src/app/src/pages/imprint.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue")
  },
  {
    name: "legal___de",
    path: "/rechtliches",
    meta: legalWk0AEMI8lGMeta || {},
    component: () => import("/usr/src/app/src/pages/legal.vue")
  },
  {
    name: "legal___en",
    path: "/en/legal",
    meta: legalWk0AEMI8lGMeta || {},
    component: () => import("/usr/src/app/src/pages/legal.vue")
  },
  {
    name: "logout___de",
    path: "/logout",
    meta: logoutAhPWW22ABKMeta || {},
    component: () => import("/usr/src/app/src/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutAhPWW22ABKMeta || {},
    component: () => import("/usr/src/app/src/pages/logout.vue")
  },
  {
    name: "payment-failure___de",
    path: "/payment/failure",
    meta: failuretSqLeewcJZMeta || {},
    component: () => import("/usr/src/app/src/pages/payment/failure.vue")
  },
  {
    name: "payment-failure___en",
    path: "/en/payment/failure",
    meta: failuretSqLeewcJZMeta || {},
    component: () => import("/usr/src/app/src/pages/payment/failure.vue")
  },
  {
    name: "payment-redirect___de",
    path: "/payment/redirect",
    meta: redirectY9j9fJdMrkMeta || {},
    component: () => import("/usr/src/app/src/pages/payment/redirect.vue")
  },
  {
    name: "payment-redirect___en",
    path: "/en/payment/redirect",
    meta: redirectY9j9fJdMrkMeta || {},
    component: () => import("/usr/src/app/src/pages/payment/redirect.vue")
  },
  {
    name: "payment-success___de",
    path: "/payment/success",
    meta: successAB6MazCFc7Meta || {},
    component: () => import("/usr/src/app/src/pages/payment/success.vue")
  },
  {
    name: "payment-success___en",
    path: "/en/payment/success",
    meta: successAB6MazCFc7Meta || {},
    component: () => import("/usr/src/app/src/pages/payment/success.vue")
  },
  {
    name: "terms___de",
    path: "/agb",
    meta: termsA1HF1pYOfSMeta || {},
    component: () => import("/usr/src/app/src/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    meta: termsA1HF1pYOfSMeta || {},
    component: () => import("/usr/src/app/src/pages/terms.vue")
  },
  {
    name: "test___de",
    path: "/test",
    meta: testk3FxbpXxpUMeta || {},
    component: () => import("/usr/src/app/src/pages/test.vue")
  },
  {
    name: "test___en",
    path: "/en/test",
    meta: testk3FxbpXxpUMeta || {},
    component: () => import("/usr/src/app/src/pages/test.vue")
  },
  {
    name: "ui___de",
    path: "/ui",
    meta: uiBlCNHk5sa8Meta || {},
    component: () => import("/usr/src/app/src/pages/ui.vue")
  },
  {
    name: "ui___en",
    path: "/en/ui",
    meta: uiBlCNHk5sa8Meta || {},
    component: () => import("/usr/src/app/src/pages/ui.vue")
  },
  {
    name: "unsubscribe-error___de",
    path: "/unsubscribe-error",
    meta: unsubscribe_45errorUd71VnFZyOMeta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-error.vue")
  },
  {
    name: "unsubscribe-error___en",
    path: "/en/unsubscribe-error",
    meta: unsubscribe_45errorUd71VnFZyOMeta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-error.vue")
  },
  {
    name: "unsubscribe-noaccount___de",
    path: "/unsubscribe-noaccount",
    meta: unsubscribe_45noaccountpCfjiofP00Meta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-noaccount.vue")
  },
  {
    name: "unsubscribe-noaccount___en",
    path: "/en/unsubscribe-noaccount",
    meta: unsubscribe_45noaccountpCfjiofP00Meta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-noaccount.vue")
  },
  {
    name: "unsubscribe-success___de",
    path: "/unsubscribe-success",
    meta: unsubscribe_45successiiKViE9bsuMeta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-success.vue")
  },
  {
    name: "unsubscribe-success___en",
    path: "/en/unsubscribe-success",
    meta: unsubscribe_45successiiKViE9bsuMeta || {},
    component: () => import("/usr/src/app/src/pages/unsubscribe-success.vue")
  },
  {
    name: "webviews-parking-payment-history___de",
    path: "/webviews/parking-payment-history",
    meta: parking_45payment_45historyqbwSwRzxyYMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/parking-payment-history.vue")
  },
  {
    name: "webviews-parking-payment-history___en",
    path: "/en/webviews/parking-payment-history",
    meta: parking_45payment_45historyqbwSwRzxyYMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/parking-payment-history.vue")
  },
  {
    name: "webviews-payment-transactionId___de",
    path: "/webviews/payment/:transactionId()",
    meta: _91transactionId_93TKz3Unac6hMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/payment/[transactionId].vue")
  },
  {
    name: "webviews-payment-transactionId___en",
    path: "/en/webviews/payment/:transactionId()",
    meta: _91transactionId_93TKz3Unac6hMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/payment/[transactionId].vue")
  },
  {
    name: "webviews-personal-data___de",
    path: "/webviews/personal-data",
    meta: personal_45databIICo6LiXGMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/personal-data.vue")
  },
  {
    name: "webviews-personal-data___en",
    path: "/en/webviews/personal-data",
    meta: personal_45databIICo6LiXGMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/personal-data.vue")
  },
  {
    name: "webviews-personal-preferences___de",
    path: "/webviews/personal-preferences",
    meta: personal_45preferences7KQ2cTr08HMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/personal-preferences.vue")
  },
  {
    name: "webviews-personal-preferences___en",
    path: "/en/webviews/personal-preferences",
    meta: personal_45preferences7KQ2cTr08HMeta || {},
    component: () => import("/usr/src/app/src/pages/webviews/personal-preferences.vue")
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubuYxk0V0rW5
  }
]