/*
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query'

// Nuxt 3 app aliases
import { defineNuxtPlugin, useState } from '#imports'

export default defineNuxtPlugin((nuxt) => {
    const vueQueryState = useState('vue-query')

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
        defaultOptions: { queries: { staleTime: 24 * 60 * 60 * 1000 } },
    })
    const options = { queryClient }

    nuxt.vueApp.use(VueQueryPlugin, options)

    if (import.meta.server) {
        nuxt.hooks.hook('app:rendered', () => {
            vueQueryState.value = dehydrate(queryClient)
        })
    }

    if (import.meta.client) {
        nuxt.hooks.hook('app:created', () => {
            hydrate(queryClient, vueQueryState.value)
        })
    }
})
*/

import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query'
import { defineNuxtPlugin, useState } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
    const vueQueryState = useState('vue-query', () => null) // Ensure state initialization

    const queryClient = new QueryClient({
        defaultOptions: { queries: { staleTime: 24 * 60 * 60 * 1000 } } // Default: 24 hours
    })

    nuxtApp.vueApp.use(VueQueryPlugin, { queryClient })

    if (import.meta.server) {
        nuxtApp.hooks.hook('app:rendered', () => {
            vueQueryState.value = dehydrate(queryClient) // Save dehydrated state on server
        })
    }

    if (import.meta.client) {
        nuxtApp.hooks.hook('app:created', () => {
            if (vueQueryState.value) {
                hydrate(queryClient, vueQueryState.value) // Rehydrate query state on client
            }
        })
    }

    // Provide `queryClient` globally
    return {
        provide: {
            queryClient
        }
    }
})
