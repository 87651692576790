import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 31"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#ffed15",
      d: "M6.4 12.4c.6-.5.6 0 1.4-.7-.2.4-.4.3 0 .3-.5.6-.3 0-.7.7.3.2.4 0 .6-.3.4-.2-.5.9 0 .6.5-.6.5-.4.1 0 .9-.6-.3.4.5-.2-.4.6.6 0 .5 1.2.5-.4.5 0 1.1 0 .6.6.4 1.6 1 1-.6.7.5-.4-.2.4.3-.3.4-.3.1.2.7-.2-.1.3.2.2.4-.5 0 .4.6.5.9-1.1-.2.4.2.3.3.4 1.5 0 .9.5 1.4-.2.1.4.8.8.5-.3.4 1.1.9 1 0 0 .7.2.6 1.1 0 .1.8-.7.6-.2-.8.3.8.5.4.7-.2.4.6-.3 0 .3.4 0 0 .4.4 0 .3.4.8.2.3.8.2 0 .8-.3.4.3 1.5 0 .3 1.4 2 .3-1.5 1.4.5 0 0 1.4.4-.4.3 0 .4 0 .5-.7.4-.2.6-.1-.9.7.4 0-.2.6.3-.2.7.2 0 .5.7.1 1.1 0 .6.4.5 0 .3.7.8.3-.6.8.7 0 .2.7-.3.3.5-.3.7-.3-1.2.7.7-.1-.2.5 1-.4-.5.5 0 .4 1.1-.4.3 0 0 .4.2 0 .4 0 0 .2 1.4 0 1.3.2.8.7.1 0 .5 0 .6-.2-.1.1-.6.4-.1 0 .8-.4-.8 0 .3-.5-.8.4.2-.2 0-.3l.2-.2c0-.6-1.5.8-.2-.5-.4.3-.8.4-.3-.2-.5.4-.3 0 0-.3-.5.3 0-.2-.1-.2-.3.3-.2 0-.4 0 .3-.6 0-.3-.3-.4.9-.6-.1-.5-.8 0 1-.8.6-.6.7-1.1-.4 0-.8.3-.6-.3-.8 0 .1-.7-.2-.9-.3.4-.1 0 .1-.2-.3-.4-1 .4-.4-.6-.7.2-1.4.6-.3-.1-.2 0 0-.5-.8-.1.4-.5.5-.7.6-1 0 .2-1.3.7-.2 0 .2-.4-.6.2-.2-.3 0 .2-1.1.6-.7.4-.4 0 .5-.8.2-.9-.5.5 0 .2-.6.8-.4-.5.9-.6 0-.9-.7.6.6-.8-.4 0 .5-.8-.3 0-.5 0 .2-.1.7-.5.3-.3-.5 0-.7.5-.6-.5-.4-.2-.4 0-.7 0 1-.8 0-.8-.6-.6.5-.4.2-.2-.1 0 .4-.7.8-1-.1-.5-.3.1 0-.2.1-.4-.9.3-.4 0 0-.2-1.2.5-.1-.4-.8-1.2-.8.9-.4 0 0-.7-.3.3-.3 0-.4.4-.4.2.3-.6 0-.4.7-.4-.9.2-.3-.2-.5 0-.9-.1-.6-.4-.4-.3-.8.3-.5-.7-.3-.2-.9 0-1-.8-.8.9 0 0-.3-.3-.5.9-.4-.5-1-.2.7-1.6-1.1.6-.4-.5-.4-1.2-.9-.2-1.4-.4.2-.2.1-.3-.1 0-.1-.1.1-.4-.2-.2 0-.6 0-.1.6-1-1 1-.2-.8-1 .3 0 .7.3-1.1-.7-.1.4-.7-.4 0 0-.5.2-.7-1 .7-.8-.3-.5 0-1-1-1.3-.5-.3.2.3-.9-.2-.5-.2.4-.4 0-.2 0-.8.5.2-.4 0-.6-1.1.9-1.9 3-1 2-.6.8.4-.4-.1.4.2-.1.6-.3.2.3.6-.6-.2.6.7.1-.4.7.9-.6-.1.6.6-.8.7 0 .2.3Zm2.6.7c-.1 0 0-.3 0-.2zm1.1-2.1s0 .1 0 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#ffed15",
      d: "M6.1 26.9c-.3-.7.4-.8-.1-1.8.3.3.1.4.3 0 .1.3.3.5 0 .4.5.9.8.1.3-.3 0-.5.8.6.6 0-.1-.2-.5-.6-.2-.5.6.8 0-.1.3 0 .4.8-.3-.6.3.2 0-.8 1.1-.9 1.3-1 0 0-.3-.6 0-.1 0-1.1 1.2-1.9 2.1-1.7 0-.2-.5-.8 0-.2-.2-.5-.2-.3.2 0-.3-.4-.2-.5.3-.2 0-.9.3.1.3-.2-.3-.2.3-.4.3-.2.8-.3-.3-1.4.4-.8-.5-.5 0-.3.1 0 0 0 1.1-.1.5-1 1.1.3 0-.9.8-.2.4-.2-.2-1.2.3-.6.2-.5 1.2.2.9-.8.1.3 1.6-.5 1.4-.7 0 0 .5-.9 1.5-.9 0-.3-.5-1.2 0-.3.7-1.6 1.4-.6 1.5-2.2.9.9 1.2-2.2 1.6-.7-.2-1.2.8-.6.7-1.3-1.3-1.6.6.3.2-.3.6.5 0-1.4 1.1-.6-.2-.6-.2-.5.1-.7-.9-.4 0-.9.3-.2-.1-.7 0-.6.2-.4 0 0-.3-.6 0-.2.6-.7.6.3.8-.8-.2-.6.7.7.6-.6.7.6.4-.9 1.1-.7.4.2-.9-1.2.2-.3-.2-.2-.3-1.1.1-.3-.2-.9.1.2.6 0-.6-.5 0-.4-.2-.9.3.9 1.2.6.7.3.7 0 0-1.7.4-1 .5-.5 1 .8.7-.5-.3-.2.4.7-.2.1-.5-.9 0 1-.5-.2.4.7-.2 0-.4 0 .1.4-.2-.1-.1 0-.3-.1 0 .5-.2.2.1.6.6.8-.3 0 .3.3.4.8-.2.4.5.6-.3 0-.5.2.3.8-.8.1-.6.9-1.1-1.3.3 1.6-.3.5 0 .3-.1-.1-.1-.2 0 .4 0 0-.5-.2.3.4.1.3-.2 0 0 .1.3.5 0 .2.3 1-.6.5-.5 1.3-.6-.1-.8-.4-.9.1-.8-1.2 0 .7-.7.1.8.8-.6-.6-.2.6.8 1.2-.9-1-.5.8-.5-.4-.8-.5-1.1-.5 0 0 .7.9.2.7 0-.3-.6-.8-.3 0-.6-.7.3.5 0 .7-.1 0-.9-.5-1.1-.2.5.6.3 0 .8.6-.4.1-.3.2-.7-.4-.2-.1-.2.7-.2.5.2.4-.3 0-.4 0 0 .4-.2.3 0 1-.5-.9-.5 0-.1.4-.9-.2-1.2.3-1.1.8-.6-1-.6 0-.9 0 .4 1.4-.5 0 0 .9-.7-.5-1-.8-.7.2 0 .6-.6-.7-.4.5-.5-1.3 0-.4-.2.1-1-.8-1.8 0-1.3.8-.6-.4-1.4-.9-.7-.3-1-.1-.4.4-1.2 1.2 0 0-.6-.1-.4.9-1.3-1.2-.6.9-2 .7.7.9-.2 0-.4.3.6.4-.8 1-.8.8-.8.3-.4.3-.1 1-.2-.3-.9-.5-.9.1-.7-.6-.2 1.3-.8 1.6-.7 0-.1.2-.8-.7.4 1 0 .5-.4.7.3.3.3.5.5.2.4 1 0 .4-.6 0-.2.9 0 1.1-.5 1.1-.8-.7.2 1.2-.7.7.3.3-1.5.8-1 1.3l-.3-.3c.7 1-1.1-.1-.3.7-.3.3-.4 0-.3.5-1.6-1.3.3 1.4.9 1.6-.2-.4 0-.3 0 0 .4.1 0-1.3 1.3-.6-.2-.6 0-.8.4-.5-.6-1-.2-.8.2 0-.6-.8.3-.8.5-.3Zm1.9-3c-.1.1-.3 0-.2-.1 0 0 .1 0 .2.1m-1.9-1.4s.1 0 0 .1z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#ffed15",
      d: "M14.4 4.2c.7.4.2.6 1.2 1-.4 0-.4-.3-.2.2-.3-.1-.5-.2-.3-.2-1-.4-.6.3 0 .3.4.3-1 0-.5.2.3 0 .8.2.5.3-1-.2.1 0-.1.1-.6-.3.4.5-.8 1 .3.2.7.5.2.7 1 .6-1.1 1.3 0 1.5-.5 0-.3 0 0 0-.3 0-.6 0 0 0-.3.3-.5.3-.5.4.5 0-.8.7.5.8 0 .2-.6-.2-.7.2-.5.5 1 .4 0 .9.9.1-.8.2.5.6-.2.2-1.3.5-.4.9-1.3.8.3.9-1.1 2.1 1.3.4-.2 0 .5 1.1-.4-.2-.5.1 0 .2-.2 0-.5.2 0 .3.2.5-.2.4 0 .7-.8-.2.8 1.5-.4.9.8.5.2.6.3.9 1.3.2.6.3 0 .3 1.5 1.4-.1.2.4 1.2.9-.2.6.4 0 .3.8.3-.2.2.4.3-.6 0 .3.1-.1 0 0 .5 0 .4-.5.3l.5.5c.7.2-1 0 0 .6-.9 0 .3.7-.2.9-.4.1 1.5.1 0 .2.3 0 .9.3.2.2 1.1.3-.7 0-.3.3 1.6.3-.8.1 0 .5-.4 0 .2.6.9.7-.7 0-.7.2-.5.3-.3 0-.7.2.1.2-1.2-.1 1 0-.1-.3 1 .1-.4-.1.6 0 .2-.2.8-.4-.4-.5.9 0 .9-.1.3-.2.3-.3.4-.2 0-.2.6 0-.1 0 .2-.2h.4c-.2-.1-.5-.5.2-.7-.8-.1-.2-.3 0-.1-.4-.3 0 0-.1-.4h-.5c.4 0 .9 0 .4-.1 0 0 .7 0 0-.2.6-.2-.6 0-.1-.6 0 0-.3-.6.3-.4.5-.3-.3-.4.5-.3 0-.4-1-.4.1-.6-.6-.3-1.5-.5-.2-.3v-.4c-.8 0-.3-.4.3-.2-.3-.2.9-.3 0-.2.6-.1-1.1 0-.3-.2.2.1 1 .1.1-.1.9 0-.2-.1-.3-.2-.9-.2 1.7-.4.2-.6.5.3-.7.2-.4 0 .4 0 1.2 0 .4-.3-.5-.3 1 0-.1-.3.9-.1-.3-.1-.3-.4.2 0 .9.1.5 0-.3-.3-.9-.3 0-.7-.1-.4-.2-.3-.5-.5.9.1.4 0 .7-.1 0-.4-1.1-.7-.1-.7.4.3-.1-.2.3 0-.4-.3-.7-.3-.7-.5.7 0 .3 0 0-.3.3.1 1.1.5.5 0-1-.1.7-.3.7-1-1.2-.1-.2-.3.6-.3-1 0-.5-.3-.1-.3.1-.1-.8-.6-.2-.4-.3-.4-.3-.7 0-.6-.2-.2.3-.2-.5-.6 1.7.1-.2-.6 1-1.2-1.1-.2 0 0 0-.3-.7-.1-.2-.9.3-1-1.4-.5-.3-.1-.2-.7.3-.5-1.5-1-.3-1.1-.4 0-.4-.2-.1-.1 0-.6.1 0 .9 0-.9-.4-.3-.3-.1-.6-.4 0-.5-.3-.5 0-1.3-.6 1.4 0-.3-.8.2 0 .7.1 0-.2h.5c.7 0-1-.6 0-.7-.4 0 .6-1.1 0-1.1.5.1.3 0 0-.1 1.2-.5-.2-.1.2-.6.4.2 0-.1 0-.2.2 0 .7.2.8 0-.6-.4-1.8-.7-2.5-.7.4.2.2.3 0 0-.2 0 .2.2-.2.1 1.2.4-.9-.1.6.3-1.2-.2.4.6-1 .4.6.3.5.5 0 .4 1.1.4.6.4-.1.1.9.3.4.5-.2.3Zm.5 2.6c0-.1.3 0 .2 0zm2.3 0H17z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#ffed15",
      d: "M6.8 18.5c.1-.8.4-.4.5-1.4 0 .4-.1.6.2.2 0 .3 0 .5-.1.4 0 .9.3.6.3 0 .1-.4.2 1 .3.5 0-.8.1-.6 0 0 .3-1 0 .5.2-.4 0 .8.3-.3.9.6.1-.6.3-.4.7-.7 1 .1 1 .7 1.1 0 0 .3 0 .6 0 0 .2-.2 0 1 .3.1-.2.7.2-.2.2.4.6.3.3-1.3.4-.3 0 .9.8.2 1.3-.5.4.8.6.6 1.1.5.6 1.2.3-.7 1.4.8.4-1.3 0 .2.9-.5-.1.8.3-.4.1.3.2-.2.4 0 .7-.5.2 1 .8 0 1.3 0 0 .8.5 0 .6-.7-.2 2.1.3-.3.7 1 0-.7.3 0 .2-.4.2-.8 0-.2.3-.3-.3 1.2.3-.3.1.5 0-.3.1-.4 0 0 .2-.5 0-.1.4-.2-.3 1 .6-.4.5.1-.1.7.2-.2.5.3 0 0 .7-.5.5.1.4-.4.2-1.2.2-.2 0-.3.4-.8.2-.1.5-.9 0 .3.1.3.5-1 .2.3.4.5.1-.5.5-.2.8-1.1-.2.6 0 .7.1.5 0 .2-.1.8.2 0v.2-.3c.1-.7-.2 0-.2 0 0-.4.2-.7 0 0 .2-.6 0-.5 0-.8-.3-.4-.5 1.6-.3-.2-.2.8-.2.3-.5.3.3-.9-.4.3-.5-.7-.3.9-.1 0-.2 0 0 0-.4.2-.5 1.1.2-.8 0-.4 0-.9.1-.3-.2.2 0-.4-.2 0-.3.7-.7.4-.1 0-.3.2-.4-.2.1-.4 0-.4-.2-.2.3-1.4-.3.5-.3-.4v.5c0-2-.7 1.7-.4-.3.2 0-.2-.6-.5.3 0-1-.1-1.5-.3-.3-.2 0 .3-1.5-.2-.4.1-.9-.1.2-.2.3-.1.5 0-.1-.3-.1 0-.9-.2-.2-.1.4-.2-.2-.2-.2 0-.8-.3-.2-.5 1-.4 0 0 0-.2.7-.2.1-.5 1.2 0-.2-.3 0-.4.7-.2.4-.9.1 0-.2-.1.7-.2.4.1-.9 0-.4-.1-.7 0-.3-.4.6-.5.7v.1c0-.7 0-1.2-.3-.3-.2.3 0-.8-.4.1.3-1.2.2-.4-.1 0 0-1.2-.8-1.2-.9-.3-.2-2.2-.1.3-.3-1.1-.9 1.1-.8-.3-1.4.9 0-1.1-.3-1-.6-.2-.2-.6-.6-.6-.5-.1-.1.3 0-.7-.3-.5v.6c-.2-.9-.4.3-.5-.5 0 .5-.3-.2-.4-.3 0 0 0 1-.2 1 0-1.5-1 0-.8-.5-.1.9 0 .4-.3.6 0-.7-.1.8-.2-.3 0 .4-.1.4 0 .1-.5.2 0-.2-.1-.9 0 .2-.1.7-.2.6 0-.7-.5.2 0 .1-.2 1.5-.3-1.4-.6.4 0-.2 0-.7-.2 0v-.5c-.3-.7-.3 1.2-.6.3 0 .4-1.1-.3-.9.3 0-.5 0-.3 0 0s-.3-.9-.4-.3c0 .4-.2.3-.2 0-.2.8-.1-.4-.3-.4-.2 1.4.3 3.5.4 2.2v-.1c.1.4 0 .7.2.2.2.7.2-.4.4.7.2-1.2.3.5.4-1 0 1.6.2-.4.3 1.1Zm1.8-1.1c-.1 0 0-.3 0-.2zM8.3 15c0 .1 0 0 0 0"
    }, null, -1)
  ])))
}
export default { render: render }