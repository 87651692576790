import { useSideBarStore } from '~/stores/sideBar'

/**
 * Inserts an ellipsis after the last word that fits within the specified character count.
 *
 * @param {string} sentence - The sentence to truncate.
 * @param {number} charCount - The maximum character count.
 * @return {string} The truncated sentence with an ellipsis appended.
 */
const insertEllipsis = (sentence, charCount) => {
    if (!sentence) {
        return ''
    }

    // Check if the sentence length is less than or equal to charCount
    if (sentence.length <= charCount) {
        return sentence
    }

    return sentence.substring(0, charCount) + '...'
}

/**
 * Shows the CMP (Consent Management Platform) screen in advanced mode.
 *
 * @return {type} None - This function does not return any value.
 */
const showCMP = () => {
    // Track Page Load
    trackPageLoad({
        title: TRACKING_CONSTANTS.TITLES.COOKIES_PREFERENCES_PAGE,
    })

    __cmp('showScreenAdvanced')
}

/**
 * Sorts an array of objects based on the order of keys provided.
 *
 * @param {Array} arr - The array of objects to be sorted.
 * @param {Array} keyOrder - The order of keys based on which the objects should be sorted.
 * @return {Array} - The sorted array of objects.
 */
const sortObjects = (arr, keyOrder) => {
    arr.sort(function (a, b) {
        let indexA, indexB

        for (let i = 0; i < keyOrder.length; i++) {
            const key = keyOrder[i]

            if (Object.prototype.hasOwnProperty.call(a, key)) {
                indexA = i
            }
            if (Object.prototype.hasOwnProperty.call(b, key)) {
                indexB = i
            }
            if (indexA && indexB) {
                break
            }
        }
        return indexA - indexB
    })
    return arr
}

/**
 * Loads an external script.
 *
 * @param {string} id - The ID of the script to be loaded.
 * @param {string} src - The URL of the script to be loaded.
 * @return {Promise} A promise that resolves when the script is loaded.
 */
const loadExternalScript = (id, src) => {
    return new Promise((resolve, reject) => {
        // Check if the script already exists by id
        if (document.getElementById(id)) {
            resolve()
            return
        }

        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.defer = true
        script.id = id
        script.onload = () => {
            resolve()
        }
        script.onerror = () => reject(new Error('Failed to load the script'))
        document.head.appendChild(script)
    })
}

/**
 * Handles the sidebar toggling based on the specified type and visibility state.
 *
 * This function closes all sidebars by default and conditionally opens the specified
 * sidebar type unless the onboarding layer is currently visible.
 *
 * @param {boolean} open - Indicates whether to open or close the sidebar.
 * @param {string} content - The content to display in the sidebar.
 * @param {string} sideBarType - The type of sidebar to toggle ('overlay', 'sidebar', 'subnav').
 * @param {boolean} [onBoardingLayerIsVisible=false] - Optional; indicates if the onboarding layer is visible.
 *
 * @returns {undefined} - This function does not return a value.
 */
const handleSidebar = (open, content, sideBarType, onBoardingLayerIsVisible = false) => {
    const sideBarStore = useSideBarStore()
    const { toggleSideBar, toggleSideBarOverlay, toggleSideBarSubNav } = sideBarStore

    // Close all sidebars by default
    toggleSideBar({ open: false })
    toggleSideBarOverlay({ open: false })
    toggleSideBarSubNav({ open: false })

    // Delay the sidebar toggle operation
    setTimeout(() => {
        if (onBoardingLayerIsVisible) {
            return // Exit if the onboarding layer is visible
        }

        // Toggle the appropriate sidebar based on the sideBarType
        const toggleOptions = { open, content }

        // Use the appropriate toggle function based on the `sideBarType` flag
        if (sideBarType === 'overlay') {
            toggleSideBar(toggleOptions)
        }

        if (sideBarType === 'sidebar') {
            toggleSideBar(toggleOptions)
        }

        if (sideBarType === 'subnav') {
            toggleSideBarSubNav(toggleOptions)
        }
    }, 500)
}

export {
    insertEllipsis,
    showCMP,
    loadExternalScript,
    handleSidebar,
}
