import { useMutation } from '@tanstack/vue-query'

export default (payload) => {
    const {
        mutationKey,
        method = 'PATCH', // Default to PATCH, but can be overridden
        apiUrl,
        headers = {},
        onSuccessCallback,
        onErrorCallback,
        onMutateCallback,
        onSettledCallback
    } = payload

    return useMutation({
        mutationKey,
        mutationFn: async (payload = null) => {
            const { dynamicUrlPart = null, body = null } = payload || {}
            const api = dynamicUrlPart ? apiUrl + dynamicUrlPart : apiUrl

            return await $fetch(api, {
                method,
                headers,
                body
            })
        },
        onMutate: async (data) => {
            if (onMutateCallback) {
                return await onMutateCallback(data)
            }
            return {}
        },
        onSuccess: async (data) => {
            if (onSuccessCallback) {
                onSuccessCallback({ data })
            }
        },
        onError: (error, context) => {
            if (onErrorCallback) {
                onErrorCallback({ error, context })
            }
        },
        onSettled: async () => {
            if (onSettledCallback) {
                onSettledCallback()
            }
        },
    })
}
