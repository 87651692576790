import { defineStore, storeToRefs } from 'pinia'

export const useSideBarStorePrivate = defineStore(
    'sideBar-store-private',
    () => {
        const sideBarContent = ref({
            open: false
        })

        const sideBarOverlayContent = ref({
            open: false
        })

        const sideBarSubNavContent = ref({
            open: false
        })

        const isSideBarLayerOpen = ref(false)

        return {
            sideBarContent,
            sideBarOverlayContent,
            sideBarSubNavContent,
            isSideBarLayerOpen
        }
    }
)

export const useSideBarStore = defineStore(
    'sideBar-store',
    () => {
        // Private sidebar store
        const sideBarStorePrivate = useSideBarStorePrivate()

        // Define sidebar getters
        const sideBarContent = computed(() => sideBarStorePrivate.sideBarContent)
        const sideBarOverlayContent = computed(() => sideBarStorePrivate.sideBarOverlayContent)
        const sideBarSubNavContent = computed(() => sideBarStorePrivate.sideBarSubNavContent)
        const isSideBarLayerOpen = computed(() => sideBarStorePrivate.isSideBarLayerOpen)

        /**
         * Toggles the sidebar content and updates the sidebar fixed class.
         *
         * @param {Object} payload - The payload containing the updated sidebar content.
         */
        const toggleSideBar = (payload) => {
            sideBarStorePrivate.sideBarContent = payload.open
                ?
                    {
                        ...sideBarStorePrivate.sideBarContent,
                        ...payload
                    } :
                    {
                        open: payload.open
                    }
        }

        /**
         * Toggles the sidebar content and updates the sidebar fixed class.
         *
         * @param {Object} payload - The payload containing the updated sidebar content.
         */
        const toggleSideBarOverlay = (payload) => {
            sideBarStorePrivate.sideBarOverlayContent = payload.open
                ?
                    {
                        ...sideBarStorePrivate.sideBarOverlayContent,
                        ...payload
                    } :
                    {
                        open: payload.open
                    }
        }

        /**
         * Toggles the sidebar sub-navigation content and updates the state.
         *
         * @param {Object} payload - The payload to update the sidebar sub-navigation content.
         * @return {void}
         */
        const toggleSideBarSubNav = (payload) => {
            sideBarStorePrivate.sideBarSubNavContent = payload.open
                ?
                    {
                        ...sideBarStorePrivate.sideBarSubNavContent,
                        ...payload
                    } :
                    {
                        open: payload.open
                    }
        }

        /**
         * Toggles the sidebar layer open state.
         *
         * @param {type} state - the payload to set the sidebar layer open state
         * @return {undefined}
         */
        const toggleSideBarLayer = (state) => {
            sideBarStorePrivate.isSideBarLayerOpen = state
        }

        return {
            sideBarContent,
            sideBarOverlayContent,
            sideBarSubNavContent,
            isSideBarLayerOpen,
            toggleSideBar,
            toggleSideBarOverlay,
            toggleSideBarSubNav,
            toggleSideBarLayer
        }
    }
)
